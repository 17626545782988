import { Timeline } from '@mui/icons-material';

import { ListSeason } from './list';
import { ShowSeason } from './show';
import { EditSeason } from './edit';
import { CreateSeason } from './create';

export default {
  icon: Timeline,
  list: ListSeason,
  show: ShowSeason,
  edit: EditSeason,
  create: CreateSeason,
};
