import React from 'react';
import { FormTab, required } from 'react-admin';

import { PriceInput } from '../../components/PriceInput';

export const PriceTab = (props) => (
  <FormTab {...props} label="app.season.days">
    <PriceInput label="app.season.1d" source="1d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.2d" source="2d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.3d" source="3d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.4d" source="4d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.5d" source="5d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.6d" source="6d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.7d" source="7d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.8d" source="8d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.9d" source="9d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.10d" source="10d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.11d" source="11d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.12d" source="12d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.13d" source="13d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.14d" source="14d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.15d" source="15d" validate={required()} defaultValue={0} />
    <PriceInput label="app.season.extraDay" source="extraDay" validate={required()} defaultValue={0} />
  </FormTab>
);
