import React from 'react';
import {
  AutocompleteInput,
  DateInput,
  DeleteButton,
  Edit,
  Form,
  NumberInput,
  ReferenceInput,
  required,
  TextInput,
  Toolbar,
} from 'react-admin';
import { Box, Grid } from '@mui/material';

import { ProviderIdInput } from '../components/ProviderIdInput';
import { PriceInput } from '../components/PriceInput';
import { SaveButtonWithConfirmation } from '../components/SaveButtonWithConfirmation';
import { validatePromoCodeForm } from './ValidatePromoCodeForm';

const UserRenderer = (choice) => `${choice.firstname || ''} ${choice.lastname || ''}`;

export const EditPromoCode = () => (
  <Edit mutationMode='pessimistic' title='app.promoCodes.title'>
    <Form validate={validatePromoCodeForm}>
      <Grid container direction='row' rowSpacing={0} columnSpacing={2} style={{ padding: 16 }}>
        <Grid item xs={6} sm={4} md={3}>
          <NumberInput label='app.promoCodes.id' source='id' readOnly fullWidth />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <DateInput label='app.promoCodes.createAt' source='createAt' readOnly fullWidth />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <DateInput label='app.promoCodes.updatedAt' source='updatedAt' readOnly fullWidth />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextInput label='app.promoCodes.code' source='code' readonly fullWidth />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextInput label='app.promoCodes.label' source='label' fullWidth validate={[required()]} />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ProviderIdInput
            label='app.promoCodes.providerId'
            source='providerId'
            validate={[required()]}
            fullWidth
            readOnly
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <DateInput
            label='app.promoCodes.start'
            source='start'
            parse={(value) => value}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <DateInput
            label='app.promoCodes.end'
            source='end'
            parse={(value) => value}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <PriceInput label='app.promoCodes.amount' source='amount' fullWidth readOnly />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <NumberInput label='app.promoCodes.percentage' source='percentage' fullWidth readOnly />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <NumberInput label='app.promoCodes.maxUse' source='maxUse' fullWidth />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ReferenceInput
            label='app.promoCodes.limitedUserId'
            source='limitedUserId'
            reference='users'
            fullWidth
          >
            <AutocompleteInput optionText={UserRenderer} />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Toolbar>
        <Box display='flex' justifyContent='space-between' width='100%'>
          <SaveButtonWithConfirmation mutationMode='pessimistic' />
          <DeleteButton />
        </Box>
      </Toolbar>
    </Form>
  </Edit>
);
