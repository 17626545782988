import { SupervisorAccount } from '@mui/icons-material';

import { ListUser } from './ListUser';
import { ShowUser } from './ShowUser';
import { EditUser } from './EditUser';
import { CreateUser } from './CreateUser';

export default {
  icon: SupervisorAccount,
  create: CreateUser,
  show: ShowUser,
  edit: EditUser,
  list: ListUser,
};
