import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

export const useSafeGetOne = (resource, { id, meta }, options) => {
  const dataProvider = useDataProvider();
  return useQuery(
    [resource, 'getOne', { id: String(id), meta }],
    () => {
      if (!id) {
        return { id: null };
      } else {
        return dataProvider.getOne(resource, { id, meta }).then(({ data }) => data);
      }
    },
    options
  );
};
