import React from 'react';
import { useRecordContext } from 'react-admin';

export const FullNameField = (props) => {
  const record = useRecordContext(props);

  return (
    <span>
      {record.firstname} {record.lastname}
    </span>
  );
};
