import React, { useEffect, useState } from 'react';
import { useTranslate, usePermissions, Button } from 'react-admin';
import { Grid, Typography, Card, CardContent, TextField, Tooltip } from '@mui/material';

import { SUPER_ADMIN } from '../users/adminTypes';

const TooltipTextField = ({ tooltip, label, value, onChange, type }) => {
  const translate = useTranslate();

  return (
    <Tooltip title={translate(tooltip)} placement="bottom">
      <TextField
        label={translate(label)}
        type={type}
        variant="outlined"
        value={value}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Tooltip>
  );
};

export const ParkingSettings = ({ parking, update }) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const [dailyMaxSpace, setDailyMaxSpace] = useState();
  const [dailyPremiumExtraSpace, setDailyPremiumExtraSpace] = useState();
  const [reservationMaxDuration, setReservationMaxDuration] = useState();
  const [firstReservationTime, setFirstReservationTime] = useState();
  const [endReservationTime, setEndReservationTime] = useState();

  useEffect(() => {
    if (parking) {
      setDailyMaxSpace(parking.dailyMaxSpace);
      setDailyPremiumExtraSpace(parking.dailyPremiumExtraSpace);
      setReservationMaxDuration(parking.reservationMaxDuration);
      setFirstReservationTime(parking.firstReservationTime);
      setEndReservationTime(parking.endReservationTime);
    }
  }, [parking]);

  const save = () => {
    update({
      dailyMaxSpace,
      dailyPremiumExtraSpace,
      reservationMaxDuration,
      firstReservationTime,
      endReservationTime,
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {translate('app.settings.parking_title')}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item>
            <TooltipTextField
              tooltip={'app.settings.parking_daily_max_space_tooltip'}
              label={'app.settings.parking_daily_max_space'}
              type="number"
              value={dailyMaxSpace || ''}
              onChange={(event) => setDailyMaxSpace(event.target.value)}
            />
          </Grid>
          <Grid item>
            <TooltipTextField
              tooltip={'app.settings.parking_premium_extra_space_tooltip'}
              label={'app.settings.parking_premium_extra_space'}
              type="number"
              value={dailyPremiumExtraSpace || ''}
              onChange={(event) => setDailyPremiumExtraSpace(event.target.value)}
            />
          </Grid>
          <Grid item>
            <TooltipTextField
              tooltip={'app.settings.reservation_max_duration_tooltip'}
              label={'app.settings.reservation_max_duration'}
              type="number"
              value={reservationMaxDuration || ''}
              onChange={(event) => setReservationMaxDuration(event.target.value)}
            />
          </Grid>
          <Grid item>
            <TooltipTextField
              tooltip={'app.settings.reservation_max_hour_tooltip'}
              label={'app.settings.reservation_max_hour'}
              type="time"
              value={endReservationTime || '00:00'}
              onChange={(event) => setEndReservationTime(event.target.value)}
            />
          </Grid>
          <Grid item>
            <TooltipTextField
              tooltip={'app.settings.reservation_min_hour_tooltip'}
              label={'app.settings.reservation_min_hour'}
              type="time"
              value={firstReservationTime || '00:00'}
              onChange={(event) => setFirstReservationTime(event.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          label="app.settings.button_save"
          variant="contained"
          color="primary"
          style={{
            marginTop: 16,
            padding: 8
          }}
          disabled={permissions !== SUPER_ADMIN}
          onClick={save}
        />
      </CardContent>
    </Card>
  );
};
