import React from 'react';
import { SelectInput } from 'react-admin';
import { withStyles } from '@mui/styles';

import { useParking } from '../useParking';
import { Loadable } from './Loadable';

const DefaultProviderIdInput = (props) => {
  const { parking, isLoading } = useParking();

  return (
    <Loadable isLoading={isLoading}>
      <SelectInput
        choices={parking?.reservationProviders}
        parse={(value) => (value.length === 0 ? null : value)}
        emptyText="app.reservation.all_providerId"
        {...props}
      />
    </Loadable>
  );
};

export const ProviderIdInput = withStyles((theme) => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
}))(DefaultProviderIdInput);
