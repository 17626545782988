import React, { useState } from 'react';
import MapIcon from '@mui/icons-material/Map';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import Box from '@mui/material/Box';
import { useTranslate, DashboardMenuItem, MenuItemLink, useSidebarState } from 'react-admin';

import { ExternalMenuItemLink } from './ExternalMenuItemLink';
import reservations from '../reservations';
import users from '../users';
import daily from '../daily';
import waitings from '../waitings';
import settings from '../settings';
import days from '../days';
import seasons from '../seasons';
import paymentCheck from '../paymentCheck';
import promoCodes from '../promoCodes';
import pendingReservations from '../pendingReservations';
import * as providers from '../providers';
import { SubMenu } from './SubMenu';
import { DailyMenuItem } from './DailyMenuItem';
import { useParking } from '../useParking';

function getTomorrowDate() {
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  return tomorrowDate;
}

function createTomorrowParkingMapUrl(baseUrl) {
  const tomorrowDate = getTomorrowDate();
  const tomorrowDateStr = tomorrowDate.toString('yyyy-MM-dd');
  const tomorrowParkingFilters = escape(`deposit-lte=${tomorrowDateStr}&pickup-gt=${tomorrowDateStr}`);

  return `${baseUrl}?noImages=1&noColors=1&filters=${tomorrowParkingFilters}`;
}

export const Menu = ({ dense = false }) => {
  const { parking } = useParking();
  const parkingMapUrl = parking?.parkingMapUrl;

  const [state, setState] = useState({
    menuReservation: true,
    menuConfiguration: false,
    menuSettings: false,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();
  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 300 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />

      {/* <DailyMenuItem */}
      <DailyMenuItem
        to='/daily'
        state={{ _scrollToTop: true }}
        primaryText={translate(`app.daily.title`)}
        leftIcon={<daily.icon />}
        dense={dense}
      />

      <SubMenu
        handleToggle={() => handleToggle('menuReservation')}
        isOpen={state.menuReservation}
        sidebarIsOpen={open}
        name='app.reservation.reservations'
        icon={<reservations.icon />}
        dense={dense}
      >
        <MenuItemLink
          to='/days'
          state={{ _scrollToTop: true }}
          primaryText={translate('app.days.title')}
          leftIcon={<days.icon />}
          dense={dense}
        />

        <MenuItemLink
          to='/waitings'
          state={{ _scrollToTop: true }}
          primaryText={translate('app.waitings.title')}
          leftIcon={<waitings.icon />}
          dense={dense}
        />

        <MenuItemLink
          to='/reservations'
          state={{ _scrollToTop: true }}
          primaryText={translate('app.reservation.advanced')}
          leftIcon={<reservations.icon />}
          dense={dense}
        />

        <MenuItemLink
          to='/pendingReservations'
          state={{ _scrollToTop: true }}
          primaryText={translate('app.pendingReservations.title')}
          leftIcon={<pendingReservations.icon />}
          dense={dense}
        />

        <MenuItemLink
          to='/paymentCheck'
          state={{ _scrollToTop: true }}
          primaryText={translate('app.paymentCheck.title')}
          leftIcon={<paymentCheck.icon />}
          dense={dense}
        />
      </SubMenu>

      {parkingMapUrl != null && (
        <SubMenu
          isOpen={true}
          sidebarIsOpen={open}
          name='app.map.title'
          icon={<MapIcon />}
          dense={dense}
        >
          <ExternalMenuItemLink
            to={parkingMapUrl}
            state={{ _scrollToTop: true }}
            primaryText={translate('app.map.today')}
            leftIcon={<MapIcon />}
            dense={dense}
          />

          <ExternalMenuItemLink
            to={createTomorrowParkingMapUrl(parkingMapUrl)}
            state={{ _scrollToTop: true }}
            primaryText={translate('app.map.tomorrow')}
            leftIcon={<MapIcon />}
            dense={dense}
          />

          <ExternalMenuItemLink
            to={`${parkingMapUrl}/editor`}
            state={{ _scrollToTop: true }}
            primaryText={translate('app.map.editor')}
            leftIcon={<EditRoadIcon />}
            dense={dense}
          />
        </SubMenu>
      )}

      <MenuItemLink
        to='/users'
        state={{ _scrollToTop: true }}
        primaryText={translate('app.user.users')}
        leftIcon={<users.icon />}
        dense={dense}
      />

      <MenuItemLink
        to='/promoCodes'
        state={{ _scrollToTop: true }}
        primaryText={translate('app.promoCodes.title')}
        leftIcon={<promoCodes.icon />}
        dense={dense}
      />

      <MenuItemLink
        to='/providers'
        state={{ _scrollToTop: true }}
        primaryText={translate('app.providers.title')}
        leftIcon={<providers.icon />}
        dense={dense}
      />

      <MenuItemLink
        to='/seasons'
        state={{ _scrollToTop: true }}
        primaryText={translate('app.season.title')}
        leftIcon={<seasons.icon />}
        dense={dense}
      />

      <MenuItemLink
        to='/settings'
        state={{ _scrollToTop: true }}
        primaryText={translate('app.settings.title')}
        leftIcon={<settings.icon />}
        dense={dense}
      />
    </Box>
  );
};
