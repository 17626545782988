import React, { Fragment } from 'react';
import 'datejs';
import {
  BooleanInput,
  BulkExportButton,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  List,
  NumberInput,
  Pagination,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
} from 'react-admin';

import { ReservationUserLink } from './ReservationUserField';
import { StatusField } from './StatusField';
import { StatusInput } from './StatusInput';
import { reservationExporter } from './reservationExporter';
import { ReservationExpandPanel } from './ReservationExpandPanel';
import { ProviderIdInput } from '../components/ProviderIdInput';
import { BulkExpandButton } from '../components/BulkExpandButton';
import { ReservationProviderAvatarField } from './ReservationProviderAvatarField';
import { SUPER_ADMIN } from '../users/adminTypes';

// TODO: use same reservation filters everywhere
const reservationFilters = [
  <NumberInput label='app.reservation.id' source='id' alwaysOn />,
  <TextInput label='app.reservation.reference' source='reference' />,
  <DateInput label='app.reservation.createAt_date-gte' source='createAt_date-gte' />,
  <DateInput label='app.reservation.createAt_date-lte' source='createAt_date-lte' />,
  <DateInput label='app.reservation.depositGte' source='deposit-gte' alwaysOn />,
  <DateInput label='app.reservation.depositLte' source='deposit-lte' alwaysOn />,
  <ProviderIdInput label='app.reservation.providerId' source='providerId' alwaysOn />,
  <TextInput label='app.reservation.email' source='email' alwaysOn />,
  <StatusInput label='app.reservation.status.title' source='status' />,
  <DateInput label='app.reservation.deposit' source='deposit' />,
  <DateInput label='app.reservation.pickup' source='pickup' />,
  <TextInput label='app.reservation.clientName' source='clientName' />,
  <TextInput label='app.reservation.lastname' source='lastname' />,
  <TextInput label='app.reservation.phoneNumber' source='phoneNumber' />,
  <TextInput label='app.reservation.carBrand' source='carBrand' />,
  <TextInput label='app.reservation.registration' source='registration' />,
  <TextInput label='app.reservation.stock' source='stock' />,
  <BooleanInput label='app.reservation.onPark' source='onPark' />,
  <TextInput label='app.reservation.transactionComment' source='transactionComment' />,
];

const PostBulkActionButtons = () => {
  return (
    <Fragment>
      <BulkExpandButton />
      <BulkExportButton />
    </Fragment>
  );
};

const PostListActions = () => {
  const { permissions } = usePermissions();

  // TODO: create a isSuperAdmin property on permissions
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      {permissions === SUPER_ADMIN ? <ExportButton maxResults={10000} /> : null}
    </TopToolbar>
  );
};

export const ListReservation = () => {
  return (
    <List
      title='app.reservation.reservations'
      filters={reservationFilters}
      exporter={reservationExporter}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<PostListActions />}
    >
      <Datagrid rowClick='expand' expand={<ReservationExpandPanel />} bulkActionButtons={<PostBulkActionButtons />}>
        <ReservationProviderAvatarField label='app.reservation.providerId' />
        <TextField label='app.reservation.id' source='id' />
        <TextField label='app.reservation.reference' source="reference" />
        <DateField label='app.reservation.createAt' source='createAt' showTime />
        <ReservationUserLink label='app.user.user' />
        <DateField label='app.reservation.deposit' source='deposit' showTime />
        <DateField label='app.reservation.pickup' source='pickup' showTime />
        <TextField label='app.reservation.stock' source='stock' />
        <StatusField label='app.reservation.status.title' source='status' />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
