import { CollectionsBookmark } from '@mui/icons-material';

import { CreateReservation } from './CreateReservation';
import { ShowReservation } from './show';
import { EditReservation } from './EditReservation';
import { ListReservation } from './ListReservation';

export default {
  icon: CollectionsBookmark,
  create: CreateReservation,
  show: ShowReservation,
  edit: EditReservation,
  list: ListReservation,
};
