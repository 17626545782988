import React, { useState } from 'react';
import { Button, FormTab } from 'react-admin';
import {
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { OptionIconButton } from '../../reservations/options/OptionIconButton';
import { useFormOptions } from '../seasonOptionsHooks';

const AddOptionButton = ({ onOptionChanged }) => {
  const [open, setOpen] = useState(false);
  const [optionLabel, setOptionLabel] = useState('');
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOptionLabel('');
  };
  const handleSumbit = () => {
    if (optionLabel && optionLabel.length > 0) {
      onOptionChanged({ label: optionLabel, price: 0, mandatory: false, defaultValue: false });
    }
    setOpen(false);
    setOptionLabel('');
  };

  return (
    <>
      <Button label='Ajouter une prestation' onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ajouter une prestation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cette nouvelle prestation sera ajouté à la saison et sera uniquement disponible uniquement durant cette
            saison.
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            label='Nom de la prestation'
            fullWidth
            variant='standard'
            value={optionLabel}
            onChange={(event) => setOptionLabel(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button label='Annuler' onClick={handleClose} />
          <Button label='Ajouter' onClick={handleSumbit} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export const OptionTab = (props) => {
  const { options, setOptions } = useFormOptions();

  const handlePriceChange = (optionIndex) => (event) => {
    const value = event.target.value;
    const price = parseInt(value);

    if (!isNaN(price)) {
      options[optionIndex].price = price * 100;
      setOptions(options);
    }
  };

  const handleMandatoryChanged = (optionIndex) => (event) => {
    options[optionIndex].mandatory = event.target.checked;
    setOptions(options);
  };

  const handleDefaultValueChanged = (optionIndex) => (event) => {
    options[optionIndex].defaultValue = event.target.checked;
    setOptions(options);
  };

  const handleAddOption = (option) => {
    options.push(option);
    setOptions(options);
  };

  const handleDeleteOption = (optionIndex) => () => {
    options.splice(optionIndex, 1);
    setOptions(options);
  };

  return (
    <FormTab {...props} label='app.season.options'>
      <List>
        {options.map((option, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <OptionIconButton index={index} option={option} disabled />
            </ListItemAvatar>
            <ListItemText primary={option.label} secondary={option.description} />
            <OutlinedInput
              sx={{ marginLeft: 16 }}
              type='number'
              value={option.price / 100}
              endAdornment={<InputAdornment position='start'>€</InputAdornment>}
              onChange={handlePriceChange(index)}
            />
            <FormControlLabel
              sx={{ marginLeft: 2 }}
              control={<Checkbox checked={option.mandatory} onChange={handleMandatoryChanged(index)} />}
              label='Inclus'
            />
            <FormControlLabel
              sx={{ marginLeft: 2 }}
              control={<Checkbox checked={option.defaultValue} onChange={handleDefaultValueChanged(index)} />}
              label='Activé par défaut'
            />
            <Button
              label='Supprimer'
              startIcon={<DeleteIcon />}
              color='error'
              sx={{ marginLeft: 2 }}
              onClick={handleDeleteOption(index)}
            />
          </ListItem>
        ))}
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <AddOptionButton onOptionChanged={handleAddOption} />
        </ListItem>
      </List>
    </FormTab>
  );
};
