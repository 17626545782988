import React from 'react';
import { Grid, Box, useMediaQuery } from '@mui/material';
import 'datejs';

import { NumberOfDailyReservation } from './NumberOfDailyReservation';
import { NumberOfCarParked } from './NumberOfCarParked';
import { NewCustomers } from './NewCustomers';
import { DailyOccupationTable } from './DailyOccupationTable';
import { MontlyOccupationTable } from './MontlyOccupationTable';

export const Dashboard = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  if (isSmall) {
    return (
      <Grid container justifyContent="flex-start" rowSpacing={2} columnSpacing={2} style={{ marginTop: 8 }}>
        <Grid item xs={12} sm={6}>
          <NumberOfDailyReservation />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberOfCarParked />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MontlyOccupationTable />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DailyOccupationTable from={new Date()} to={new Date().addDays(14)} />
        </Grid>
        <Grid item xs={12}>
          <NewCustomers />
        </Grid>
      </Grid>
    );
  }

  return (
    <Box display="flex" style={{ marginTop: 16 }}>
      <Box flex={2} mr={1}>
        <Box display="flex">
          <Box flex={1} mr={1}>
            <NumberOfDailyReservation />
          </Box>
          <Box flex={1} ml={1}>
            <NumberOfCarParked />
          </Box>
        </Box>
        <Box display="flex" mt={2}>
          <Box flex={1} mr={1}>
            <MontlyOccupationTable />
          </Box>
          <Box flex={1} ml={1}>
            <DailyOccupationTable from={new Date()} to={new Date().addDays(14)} />
          </Box>
        </Box>
      </Box>

      <Box flex={1} ml={1}>
        <Grid item md={4}>
          <NewCustomers />
        </Grid>
      </Box>
    </Box>
  );
};
