import React, { useMemo } from 'react';
import { ReservationExpandPanel } from '../reservations/ReservationExpandPanel';
import { useRecordContext } from 'react-admin';

export const DailyExpandPanel = (props) => {
  const record = useRecordContext(props);
  const reservation = useMemo(
    () => ({
      ...record,
      id: record.id.substr(0, record.id.indexOf('-')),
    }),
    [record]
  );

  return <ReservationExpandPanel {...props} record={reservation} />;
};
