import React, { useMemo } from 'react';
import { TextField, useRecordContext } from 'react-admin';

export const PromoCodeUsageField = (props) => {
  const record = useRecordContext(props);

  const usage = useMemo(() => {
    const numberOfUsage = record.numberOfUsage || record.usage?.reservationCount + record.usage?.pendingReservationCount || 0;
    const pendingUsage = record.usage?.pendingReservationCount || 0;

    return numberOfUsage + (pendingUsage > 0 ? ` donc ${pendingUsage} réservation(s) non confirmée` : '');
  }, [record]);
  
  const color = useMemo(() => {
    const numberOfUsage = record.numberOfUsage || record.usage?.reservationCount + record.usage?.pendingReservationCount || 0;

    if (numberOfUsage === record.maxUse) {
      return 'green';
    } else if (numberOfUsage > 0) {
      return 'blue';
    } else {
      return null;
    }
  }, [record]);

  return <TextField {...props} source="value" record={{ value: `${usage} / ${record.maxUse}` }} color={color} />;
};
