import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { AutocompleteInput, ReferenceField, ReferenceInput, required, TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { UserRenderer } from './index';
import { SectionTitle } from '../EditReservation/SectionTitle';
import { FullNameField } from '../FullNameField';
import { useSafeGetOne } from './useSafeGetOne';

const SelectUserInput = () => {
  const { watch, setValue } = useFormContext();
  const watchUserId = watch("userId");
  const { data } = useSafeGetOne('users', { id: watchUserId });

  useEffect(() => {
    if (data != null && data.id != null) {
      setValue('userId', data.id, { shouldDirty: true });
      setValue('firstname', data.firstname, { shouldDirty: true });
      setValue('lastname', data.lastname, { shouldDirty: true });
      setValue('email', data.email, { shouldDirty: true });
      setValue('phoneNumber', data.mobile, { shouldDirty: true });
      setValue('address', data.address, { shouldDirty: true });
      setValue('zipcode', data.zipcode, { shouldDirty: true });
      setValue('city', data.city, { shouldDirty: true });
      setValue('carBrand', data.carBrand, { shouldDirty: true });
      setValue('registration', data.registration, { shouldDirty: true });
      setValue('clientName', data.clientName, { shouldDirty: true });
      setValue('clientService', data.clientService, { shouldDirty: true });
      setValue('clientAddress', data.clientAddress, { shouldDirty: true });
      setValue('clientZipcode', data.clientZipcode, { shouldDirty: true });
      setValue('clientCity', data.clientCity, { shouldDirty: true });
    }
  }, [data, setValue]);

  return (
    <ReferenceInput label='app.reservation.userId' source='userId' reference='users' fullWidth>
      <AutocompleteInput optionText={UserRenderer} />
    </ReferenceInput>
  );
};

export const TravelerInformation = ({ disableChangeUser }) => {
  return (
    <>
      <Box display='flex'>
        <Box flex={1} mr='0.5em'>
          <SectionTitle label='app.reservation.titles.user_information'>
            <ReferenceField label='app.reservation.userId' source='userId' reference='users' fullWidth link='show'>
              <FullNameField source='firstname' prefix={' - '} />
            </ReferenceField>
          </SectionTitle>
        </Box>
      </Box>

      {!disableChangeUser && <SelectUserInput />}

      <Box display='flex'>
        <Box flex={1} mr='0.5em'>
          <TextInput
            label='app.reservation.firstname'
            source='firstname'
            resource='reservation'
            fullWidth
            validate={[required()]}
          />
        </Box>
        <Box flex={1} ml='0.5em'>
          <TextInput
            label='app.reservation.lastname'
            source='lastname'
            resource='reservation'
            fullWidth
            validate={[required()]}
          />
        </Box>
      </Box>

      <Box display='flex'>
        <Box flex={2} mr='0.5em'>
          <TextInput
            label='app.reservation.email'
            source='email'
            resource='reservation'
            type='email'
            fullWidth
            validate={[required()]}
          />
        </Box>
        <Box flex={1} ml='0.5em'>
          <TextInput
            label='app.reservation.phoneNumber'
            source='phoneNumber'
            resource='reservation'
            fullWidth
            validate={[required()]}
          />
        </Box>
      </Box>

      <Box display='flex'>
        <Box flex={3} mr='0.5em'>
          <TextInput label='app.reservation.address' source='address' resource='reservation' fullWidth />
        </Box>
        <Box flex={1} ml='0.5em'>
          <TextInput label='app.reservation.zipcode' source='zipcode' resource='reservation' fullWidth />
        </Box>
        <Box flex={1} ml='0.5em'>
          <TextInput label='app.reservation.city' source='city' resource='reservation' fullWidth />
        </Box>
      </Box>

      <Box display='flex'>
        <Box flex={1} mr='0.5em'>
          <TextInput label='app.reservation.carBrand' source='carBrand' resource='reservation' fullWidth />
        </Box>
        <Box flex={1} ml='0.5em'>
          <TextInput label='app.reservation.registration' source='registration' resource='reservation' fullWidth />
        </Box>
      </Box>
    </>
  );
};
