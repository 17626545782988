import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { FilterList } from 'react-admin';
import { useParking } from '../useParking';
import { ProviderFilterListItem } from '../seasons/ProviderFilterListItem';

export const AsideProviderFilter = () => {
  const { parking } = useParking();

  return (
    <Box>
      <Card
        sx={{
          display: { xs: 'none', md: 'block' },
          order: -1,
          width: '22em',
          mr: 2,
          alignSelf: 'flex-start',
        }}
      >
        <CardContent sx={{ pt: 1 }}>
          <FilterList label="app.providers.title">
            {parking.reservationProviders &&
              parking.reservationProviders.map((provider) => (
                <ProviderFilterListItem provider={provider} key={provider.id} value={{ providerId: provider.id }} />
              ))}
          </FilterList>
        </CardContent>
      </Card>
    </Box>
  );
};
