import React, { useState, useMemo, useCallback } from 'react';
import { SaveButton, Confirm, useTranslate, useSaveContext, useEditController } from 'react-admin';
import { useFormState, useFormContext } from 'react-hook-form';
import DialogContentText from '@mui/material/DialogContentText';
import { setSubmissionErrors } from 'ra-core';

function formatResourceName(resource) {
  switch (resource) {
    case 'reservations':
      return 'reservation';
    case 'users':
      return 'user';
    case 'seasons':
      return 'season';
    default:
      return resource;
  }
}

function fixCustomTranslations(field) {
  switch (field) {
    case 'app.reservation.status':
      return 'app.reservation.status.title';
    default:
      return field;
  }
}

const SaveConfirmationContent = ({ dirtyFields }) => {
  const translate = useTranslate();
  const { resource } = useEditController();
  const translateResourceName = formatResourceName(resource);
  const fields = useMemo(
    () =>
      Object.entries(dirtyFields || {})
        .filter((item) => item[1] === true)
        .map((item) => fixCustomTranslations(`app.${translateResourceName}.${item[0]}`)),
    [dirtyFields]
  );

  return (
    <DialogContentText>
      {translate('app.buttons.save_with_confirmation.message')}
      <br />
      <br />
      {fields.map((field, index) => (
        <span key={index}>
          <b>{translate(field)}</b>
          <br />
        </span>
      ))}
    </DialogContentText>
  );
};

// TODO: mutation mode should not be given as props
export const SaveButtonWithConfirmation = ({ mutationMode, ...props }) => {
  const [open, setOpen] = useState(false);
  const form = useFormContext();
  const { dirtyFields } = useFormState();
  const saveContext = useSaveContext();

  const handleClick = (event) => {
    event.preventDefault();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleSubmit = useCallback(
    async (values) => {
      let errors;
      if (saveContext?.save) {
        errors = await saveContext.save(values, mutationMode);
      }
      if (errors != null) {
        setSubmissionErrors(errors, form.setError);
      }
    },
    [form.setError, saveContext, mutationMode]
  );
  const handleConfirm = async (event) => {
    setOpen(false);
    event.stopPropagation();
    await form.handleSubmit(handleSubmit)(event);
  };

  return (
    <>
      <SaveButton {...props} onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={saveContext.saving}
        title="ra.action.save"
        content={<SaveConfirmationContent dirtyFields={dirtyFields} />}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
