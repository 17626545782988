import { createTheme } from '@mui/material/styles';
import red from '@mui/material/colors/red';

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#90caf9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9fbe43',
      contrastText: '#fff',
    },
    background: {
      default: '#0F100F',
    },
    warning: {
      main: '#cd6133',
    },
    mode: 'dark',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#ffffffb3',
          backgroundColor: '#616161e6',
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#194771',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9fbe43',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
    warning: {
      main: '#ffeaa7',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
    mode: 'light',
  },
  // typography: {
  //   h1: {
  //     fontSize: 36,
  //     fontStyle: 'bold',
  //   },
  //   h2: {
  //     fontSize: 24,
  //   },
  //   h3: {
  //     fontSize: 12,
  //     fontStyle: 'bold',
  //   },
  //   h4: {
  //     fontSize: 12,
  //   },
  //   h5: {
  //     fontSize: 12,
  //   },
  //   h6: {
  //     fontSize: 12,
  //   },
  // },
});
