import { CardGiftcard } from '@mui/icons-material';

import { CreatePromoCode } from './CreatePromoCode';
import { ShowPromoCode } from './ShowPromoCode';
import { EditPromoCode } from './EditPromoCode';
import { ListPromoCode } from './ListPromoCode';

export default {
  icon: CardGiftcard,
  create: CreatePromoCode,
  show: ShowPromoCode,
  edit: EditPromoCode,
  list: ListPromoCode,
};
