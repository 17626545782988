import React from 'react';
import PropTypes from 'prop-types';
import { TextField, useTranslate, useRecordContext } from 'react-admin';

import { CANCELED, WAITING_FOR_ACCEPTANCE, WAITING_FOR_DEPOSIT, MISSING_CLIENT, RECEIVED, RETURNED } from './status';

/**
 * Format reservation status.
 */
export const StatusField = ({ label, source, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  const value = record[source];
  const newRecord = {
    ...record,
  };

  switch (value) {
    case CANCELED:
      newRecord[source] = translate('app.reservation.status.cancel');
      break;
    case WAITING_FOR_ACCEPTANCE:
      newRecord[source] = translate('app.reservation.status.waitingForAcceptance');
      break;
    case WAITING_FOR_DEPOSIT:
      newRecord[source] = translate('app.reservation.status.waitingForDeposit');
      break;
    case MISSING_CLIENT:
      newRecord[source] = translate('app.reservation.status.missingClient');
      break;
    case RECEIVED:
      newRecord[source] = translate('app.reservation.status.receive');
      break;
    case RETURNED:
      newRecord[source] = translate('app.reservation.status.returned');
      break;
    default:
      break;
  }

  return <TextField label={label} source={source} record={newRecord} />;
};

StatusField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
};
