import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  ShowButton,
  TextInput,
  NumberInput,
  DateField,
} from 'react-admin';

import { PercentageField } from '../components/PercentageField';
import { PriceField } from '../components/PriceField';
import { PriceInput } from '../components/PriceInput';
import { PromoCodeUsageField } from './PromoCodeUsageField';

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="app.promoCodes.code" source="code" alwaysOn />
    <PriceInput label="app.promoCodes.amount" source="amount" />
    <NumberInput label="app.promoCodes.percentage" source="percentage" />
  </Filter>
);

export const ListPromoCode = () => (
  <List title="app.promoCodes.title" filters={<Filters />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="id" label="app.promoCodes.id" />
      <DateField source="createAt" label="app.promoCodes.createAt" />
      <TextField source="code" label="app.promoCodes.code" />
      <TextField source="label" label="app.promoCodes.label" />
      <PriceField source="amount" label="app.promoCodes.amount" />
      <PercentageField source="percentage" label="app.promoCodes.percentage" />
      <PromoCodeUsageField label="app.promoCodes.usage" />
      <ShowButton />
    </Datagrid>
  </List>
);
