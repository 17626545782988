import React from 'react';
import {
  AutocompleteInput,
  Create,
  DateInput,
  Form,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { Divider, Grid, Typography } from '@mui/material';

import { PriceInput } from '../components/PriceInput';
import { ProviderIdInput } from '../components/ProviderIdInput';
import { validatePromoCodeForm } from './ValidatePromoCodeForm';

const optionRenderer = (choice) => (choice ? `${choice.firstname || ''} ${choice.lastname || ''}` : '');

export const CreatePromoCode = () => (
  <Create title="app.promoCodes.title" redirect="show">
    <Form validate={validatePromoCodeForm}>
      <div style={{ padding: 16 }}>
        <Typography variant="h4" gutterBottom>
          Nouveau code promo
        </Typography>
        <Divider style={{ width: '100%', marginTop: 16, marginBottom: 24 }} />
        <Grid container columnSpacing={4}>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput label="app.promoCodes.code" source="code" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput label="app.promoCodes.label" source="label" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <ProviderIdInput source="providerId" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <ReferenceInput label="app.promoCodes.limitedUserId" source="limitedUserId" reference="users" fullWidth>
              <AutocompleteInput optionText={optionRenderer} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <DateInput
              label="app.promoCodes.start"
              source="start"
              parse={(value) => value}
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <DateInput
              label="app.promoCodes.end"
              source="end"
              parse={(value) => value}
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <PriceInput label="app.promoCodes.amount" source="amount" fullWidth />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <NumberInput label="app.promoCodes.percentage" source="percentage" fullWidth />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <NumberInput label="app.promoCodes.maxUse" source="maxUse" fullWidth />
          </Grid>
        </Grid>
      </div>

      <Toolbar>
        <SaveButton />
      </Toolbar>
    </Form>
  </Create>
);
