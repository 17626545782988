import { fetchUtils } from 'react-admin';

export const httpClient = (url, options = {}) => {
    url = `${process.env.REACT_APP_API_URL}/admin${url}`;

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    // Allow cross orgin cookies
    options.credentials = "include";

    return fetchUtils.fetchJson(url, options);
}
