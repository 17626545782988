import React from 'react';
import { NumberInput } from 'react-admin';
import { InputAdornment } from '@mui/material';

export const PriceInput = (props) => {
  return (
    <NumberInput
      min={0}
      format={convertPriceNumberToString}
      parse={parsePriceStringToNumber}
      defaultValue={0}
      InputProps={{
        endAdornment: <InputAdornment position='end'>€</InputAdornment>
      }}
      {...props}
    />
  );
};

const convertPriceNumberToString = value => {
  return value == null || isNaN(value) ? '' : (value / 100).toString()
}

const parsePriceStringToNumber = value => {
  return value == null || isNaN(value) ? null : Math.round(parseFloat(value) * 100)
}
