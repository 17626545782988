import React from 'react';
import BlockIcon from '@mui/icons-material/Block';
import { useNotify, useRefresh, useRecordContext, useUpdate, useTranslate } from 'react-admin';

import { ButtonWithConfirmation } from '../../components/ButtonWithConfirmation';
import { MISSING_CLIENT } from '../status';

export const MissingClientReservationButton = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const record = useRecordContext(props);
  const [setClientMissing, { isLoading }] = useUpdate(
    'reservations',
    { id: record.id, data: { status: MISSING_CLIENT }, previousData: record },
    {
      onSuccess: () => {
        refresh();
        notify('app.reservation.status_updated_success', { type: 'info' });
      },
      onFailure: () => {
        notify('app.reservation.status_updated_failed', { type: 'warning' });
      },
    },
  );

  return (
    <ButtonWithConfirmation
      variant='contained'
      label='app.reservation.missing_client.action'
      startIcon={<BlockIcon />}
      {...props}
      onConfirmed={() => setClientMissing()}
      disabled={isLoading || record.status === MISSING_CLIENT}
      isLoading={isLoading}
      confirmationTitle='app.reservation.missing_client.confirmation_title'
      confirmationMessage={translate('app.reservation.missing_client.confirmation_message', {
        status: translate('app.reservation.status.missingClient'),
      })}
    />
  );
};
