import React from 'react';
import { SelectInput } from 'react-admin';

import { CUSTOMER, COMPANY, TRAVEL_AGENCY } from './userTypes';

export const UserTypeInput = (props) => (
  <SelectInput
    label="app.user.type"
    {...props}
    choices={[
      { id: CUSTOMER, name: 'app.user.types.customer' },
      { id: COMPANY, name: 'app.user.types.company' },
      { id: TRAVEL_AGENCY, name: 'app.user.types.travel_agency' },
    ]}
  />
);
