import React, { useState } from 'react';
import { Button, Confirm } from 'react-admin';

export const ButtonWithConfirmation = ({
  isLoading,
  confirmationTitle,
  confirmationMessage,
  onConfirmed,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    onConfirmed();
    setOpen(false);
  };

  return (
    <>
      <Button {...props} onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={confirmationTitle}
        content={confirmationMessage}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
