import React from 'react';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';

import { OptionIconButtonRoot } from './OptionIconButtonRoot';

export function isCarCondition(option) {
  const label = option.label.toLowerCase();
  return label.includes('état') || label.includes('etat');
}

export const CarCondition = (props) => {
  return (
    <OptionIconButtonRoot {...props} backgroundColor='#bdbdbd'>
      <LinkedCameraIcon />
    </OptionIconButtonRoot>
  );
};