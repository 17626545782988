import { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { httpClient } from '../httpClient';

export function useFetch(url) {
  const [retryCount, setRetryCount] = useState(0);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  async function fetchUrl(fetchUrl, options = {}) {
    try {
      setLoading(true);
      const { status, json } = await httpClient(fetchUrl, {
        ...options,
        body: options.body ? JSON.stringify(options.body) : options.body,
      });

      if (status === 200) {
        setData(json);
      } else {
        setError(Error('Status code is not 200'));
      }
    } catch (err) {
      notify(err.message, { type: 'error' });
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  function reload(url, options) {
    if (!!url) {
      fetchUrl(url, options);
    } else {
      setRetryCount(retryCount + 1);
    }
  }

  useEffect(() => {
    if (!!url) {
      fetchUrl(url);
    }
  }, [url, retryCount]);

  return { data, error, isLoading, fetch: reload };
}
