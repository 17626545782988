import React, { useEffect } from 'react';
import { Button } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { useFetch } from '../../dashboard/useFetch';

export const UpdatePricesButton = (props) => {
  const { data, isLoading, fetch } = useFetch();
  const {
    setValue,
    getValues,
    formState: { isDirty },
  } = useFormContext();

  const updatePrices = () => {
    fetch(`/reservations/prices`, {
      method: 'POST',
      body: {
        deposit: getValues('deposit'),
        pickup: getValues('pickup'),
        providerId: getValues('providerId'),
        userId: getValues('userId'),
        promoCodeId: getValues('promoCodeId'),
        options: getValues('options'),
      },
    });
  };

  useEffect(() => {
    if (data) {
      for (const property in data) {
        setValue(property, data[property]);
      }
    }
  }, [data, setValue]);

  return (
    <Button
      {...props}
      variant="contained"
      label="app.reservation.update_dialog_update_prices"
      fullWidth
      disabled={!isDirty || isLoading}
      style={{ fontSize: 18 }}
      onClick={updatePrices}
    />
  );
};
