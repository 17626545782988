import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { EditBase, Form, FormTab, TabbedFormView, Title, Toolbar } from 'react-admin';

import { ReservationTitle } from '../ReservationTitle';
import { SaveButtonWithConfirmation } from '../../components/SaveButtonWithConfirmation';
import { ReservationInternalInformation } from './ReservationInternalInformation';
import { ReservationPricingInformation } from './ReservationPricingInformation';
import { EditReservationOptionsTab } from './EditReservationOptionsTab';
import { EditReservationInformationTab } from './EditReservationInformationTab';
import { FormMatchingSeasonContextProvider } from './FormMatchingSeason';

const EditReservationToolbar = () => (
  <Toolbar>
    <Box display="flex" justifyContent="space-between" width="100%">
      <SaveButtonWithConfirmation mutationMode="pessimistic" />
    </Box>
  </Toolbar>
);

export const EditReservation = () => {
  return (
    <EditBase mutationMode="pessimistic" redirect="show">
      <Title title={<ReservationTitle />} />
      <Form>
        <FormMatchingSeasonContextProvider>
          <Box display="flex" style={{ marginTop: '1em' }}>
            <Box flex={3} mr="0.5em">
              <Card>
                <TabbedFormView toolbar={<EditReservationToolbar />}>
                  <FormTab label="app.reservation.reservation">
                    <EditReservationInformationTab disableChangeUser />
                  </FormTab>
                  <FormTab label="app.reservation.options">
                    <EditReservationOptionsTab />
                  </FormTab>
                </TabbedFormView>
              </Card>
            </Box>

            <Box flex={1} ml="0.5em">
              <Card>
                <CardContent>
                  <ReservationInternalInformation />
                  <ReservationPricingInformation disabledPromoCode sx={{ marginTop: 1 }}/>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </FormMatchingSeasonContextProvider>
      </Form>
    </EditBase>
  );
};
