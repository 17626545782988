import React, { useState } from 'react';
import StorageIcon from '@mui/icons-material/Storage';
import { Title, TopToolbar, ListContextProvider, useListFilterContext } from 'react-admin';
import { Box } from '@mui/material';

import { useParking } from '../useParking';
import { ProviderInformation } from './ProviderInformation';
import { AsideProviderFilter } from './AsideProviderFilter';

export const icon = StorageIcon;

export const Component = () => {
  const data = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }];

  return (
    <OfflineList data={data}>
      <Title title={'app.providers.title'} />
      <TopToolbar sx={{ minHeight: { sm: 56 } }} />
      <Box>
        <Box display="flex">
          <AsideProviderFilter />
          <Box flex={1}>
            <ProviderInformationContainer />
          </Box>
        </Box>
      </Box>
    </OfflineList>
  );
};

const OfflineList = ({ data, children }) => {
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const perPage = 10;
  const sort = { field: 'id', order: 'ASC' };
  const total = data.length;
  const displayedFilters = [];
  const setFilters = (filters) => setFilter(filters);
  const hideFilter = true;

  return (
    <ListContextProvider
      value={{
        data,
        total,
        page,
        perPage,
        setPage,
        filterValues: filter,
        displayedFilters,
        hideFilter,
        setFilters,
        sort,
      }}
    >
      <div>{children}</div>
    </ListContextProvider>
  );
};

const ProviderInformationContainer = (props) => {
  const { filterValues } = useListFilterContext(props);
  const providerId = filterValues?.providerId;
  const { parking, isLoading } = useParking();
  const selectedProvider = !isLoading && parking.reservationProviders.find((provider) => provider.id == providerId);

  return <div>{selectedProvider && <ProviderInformation provider={selectedProvider} />}</div>;
};
