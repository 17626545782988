export const seasonValidator = (values) => {
  const errors = {};

  if (!values.beginAt) {
    errors.beginAt = 'Ce champ est obligatoire';
  }

  if (!values.endAt) {
    errors.endAt = 'La date de fin est obligatoire';
  }

  if (values.endAt && new Date(values.endAt) < new Date()) {
    errors.endAt = 'La date de fin ne peut pas être avant la date actuelle';
  }

  if (values.beginAt && values.endAt && new Date(values.endAt) <= new Date(values.beginAt)) {
    errors.endAt = 'Date de fin ne pas être avant la date de début';
  }

  return errors;
};
