import React from 'react';
import { Box } from '@mui/material';
import { TextInput } from 'react-admin';

export const ProfessionalInformation = () => {
  return (
    <>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <TextInput label="app.reservation.clientName" source="clientName" resource="reservation" fullWidth />
        </Box>
        <Box flex={1} ml="0.5em">
          <TextInput label="app.reservation.clientService" source="clientService" resource="reservation" fullWidth />
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={3} mr="0.5em">
          <TextInput label="app.reservation.clientAddress" source="clientAddress" resource="reservation" fullWidth />
        </Box>
        <Box flex={1} ml="0.5em">
          <TextInput label="app.reservation.clientZipcode" source="clientZipcode" resource="reservation" fullWidth />
        </Box>
        <Box flex={1} ml="0.5em">
          <TextInput label="app.reservation.clientCity" source="clientCity" resource="reservation" fullWidth />
        </Box>
      </Box>
    </>
  );
};
