import React from 'react';
import {
  Edit,
  Toolbar,
  TabbedForm,
  DeleteButton,
} from 'react-admin';
import { Box } from '@mui/material';

import { seasonValidator } from '../seasonValidator';
import { SeasonTitle } from '../SeasonTitle';
import { SaveButtonWithConfirmation } from '../../components/SaveButtonWithConfirmation';
import { InformationTab } from './InformationTab';
import { PriceTab } from './PriceTab';
import { OptionTab } from './OptionTab';

const EditSeasonToolbar = () => (
  <Toolbar>
    <Box display="flex" justifyContent="space-between" width="100%">
      <SaveButtonWithConfirmation mutationMode="pessimistic" />
      <DeleteButton />
    </Box>
  </Toolbar>
);

export const EditSeason = (props) => (
  <Edit mutationMode="pessimistic" {...props} title={<SeasonTitle />}>
    <TabbedForm validate={seasonValidator} toolbar={<EditSeasonToolbar />}>
      <InformationTab />
      <PriceTab />
      <OptionTab />
    </TabbedForm>
  </Edit>
);
