import React from 'react';
import { DateTimeInput as RaDateTimeInput } from 'react-admin';

const leftPad =
  (nb = 2) =>
  (value) =>
    ('0'.repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);

const convertDateToString = (value) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return '';
  const yyyy = leftPad4(value.getFullYear());
  const MM = leftPad2(value.getMonth() + 1);
  const dd = leftPad2(value.getDate());
  const hh = leftPad2(value.getHours());
  const mm = leftPad2(value.getMinutes());
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
};

const convertDateToApiString = (value) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return '';
  const yyyy = leftPad4(value.getFullYear());
  const MM = leftPad2(value.getMonth() + 1);
  const dd = leftPad2(value.getDate());
  const hh = leftPad2(value.getHours());
  const mm = leftPad2(value.getMinutes());
  return `${yyyy}-${MM}-${dd} ${hh}:${mm}`;
};

// input -> record
const parseDateTime = (value) => convertDateToApiString(new Date(value));

// record -> input
const formatDateTime = (value) => convertDateToString(new Date(value));

export const DateTimeInput = (props) => {
  return <RaDateTimeInput {...props} parse={parseDateTime} format={formatDateTime} />;
};
