import React from 'react';
import { useRecordContext } from 'react-admin';

export const TimeField = ({ source, ...props }) => {
  const record = useRecordContext(props);
  const date = new Date(record[source]);

  function addZeroBefore(n) {
    return (n < 10 ? '0' : '') + n;
  }

  return (
    <span>
      {addZeroBefore(date.getHours())}:{addZeroBefore(date.getMinutes())}
    </span>
  );
};
