import React from 'react';
import { TextField, EmailField, Labeled, BooleanField, RichTextField, ReferenceField, DateField } from 'react-admin';
import { Typography, Grid, Divider } from '@mui/material';
import { UserTypeField } from '../UserTypeField';
import { TextFieldwithSuffix } from './TextFieldwithSuffix';

const TechnicalInformation = () => (
  <>
    <Grid item>
      <Typography variant="h6" gutterBottom>
        Information technique
      </Typography>
      <Labeled label="Id">
        <TextField source="id" />
      </Labeled>
    </Grid>
    <Grid item>
      <Labeled label="app.user.createAt">
        <DateField source="createAt" showTime />
      </Labeled>
    </Grid>
    <Grid item>
      <Labeled label="app.user.type">
        <UserTypeField source="type" />
      </Labeled>
    </Grid>
    <Grid item>
      <Labeled label="app.user.premium">
        <BooleanField source="premium" />
      </Labeled>
    </Grid>
    <Grid item>
      <Labeled label="app.user.bonus">
        <TextFieldwithSuffix source="bonus" suffix="%" />
      </Labeled>
    </Grid>
    <Grid item>
      <Labeled label="app.user.priceList">
        <ReferenceField
          source="priceList"
          reference="seasons"
          link="show"
        >
          <TextField label="app.priceList.name" source="name" />
        </ReferenceField>
      </Labeled>
    </Grid>
    <Grid item>
      <Labeled label="app.user.comment">
        <RichTextField source="comment" />
      </Labeled>
    </Grid>
  </>
);

const UserDetails = () => (
  <>
    <Typography variant="h6" gutterBottom>
      Information client
    </Typography>
    <Grid container>
      <Grid
        item
        xs={6}
        md={3}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={1}
      >
        <Grid item>
          <Labeled label="app.user.firstname">
            <TextField source="firstname" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.lastname">
            <TextField source="lastname" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.email">
            <EmailField source="email" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.mobile">
            <TextField source="mobile" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid
        item
        xs={6}
        md={3}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={1}
      >
        <Grid item>
          <Labeled label="app.user.address">
            <TextField source="address" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.zipcode">
            <TextField source="zipcode" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.city">
            <TextField source="city" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid
        item
        xs={6}
        md={3}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={1}
      >
        <Grid item>
          <Labeled label="app.user.carBrand">
            <TextField source="carBrand" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.registration">
            <TextField source="registration" />
          </Labeled>
        </Grid>
      </Grid>
    </Grid>
  </>
);

const ProfessionalInformation = () => (
  <>
    <Typography variant="h6" gutterBottom>
      Information professionelles
    </Typography>
    <Grid container>
      <Grid
        item
        xs={6}
        md={3}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={1}
      >
        <Grid item>
          <Labeled label="app.user.clientName">
            <TextField source="clientName" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.clientService">
            <TextField source="clientService" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid
        item
        xs={6}
        md={3}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={1}
      >
        <Grid item>
          <Labeled label="app.user.clientAddress">
            <TextField source="clientAddress" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.clientZipcode">
            <TextField source="clientZipcode" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="app.user.clientCity">
            <TextField source="clientCity" />
          </Labeled>
        </Grid>
      </Grid>
    </Grid>
  </>
);

export const UserInformation = () => (
  <Grid container columnSpacing={1} style={{marginTop: 8}}>
    <Grid
      item
      xs={12}
      sm={9}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowSpacing={1}
    >
      <UserDetails />
      <Divider style={{ width: '95%', margin: 'auto' }} />
      <ProfessionalInformation />
    </Grid>
    <Grid
      item
      xs={12}
      sm={3}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowSpacing={1}
    >
      <TechnicalInformation />
    </Grid>
  </Grid>
);
