import React from 'react';
import { Create, TabbedForm } from 'react-admin';

import { seasonValidator } from '../seasonValidator';
import { SeasonTitle } from '../SeasonTitle';
import { InformationTab } from '../edit/InformationTab';
import { PriceTab } from '../edit/PriceTab';
import { OptionTab } from '../edit/OptionTab';

export const CreateSeason = (props) => {
  return (
    <Create {...props} title={<SeasonTitle />} redirect="show">
      <TabbedForm validate={seasonValidator}>
        <InformationTab />
        <PriceTab />
        <OptionTab />
      </TabbedForm>
    </Create>
  );
};
