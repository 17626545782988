import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import 'datejs';

/**
 * Replace export ',' delimiter with ';'.
 */
export const dailyExporter = (reservations) => {
  jsonExport(
    reservations.map((reservation) => {
      return {
        depositTime: new Date(reservation.deposit).toLocaleTimeString('fr-FR'),
        pickupDate: new Date(reservation.pickup).toLocaleDateString('fr-FR'),
        stock: reservation.stock,
        carBrand: reservation.carBrand,
        registration: reservation.registration,
        reference: reservation.reference,
      };
    }),
    {
      headers: ['depositTime', 'pickupDate', 'stock', 'carBrand', 'registration', 'reference'],
      rename: ['HEURE DEPOT', 'DATE RETOUR', 'EMPLACEMENT', 'VEHICULE', 'IMMATRICULATION', 'REFERENCE BROKER'],
      rowDelimiter: ';',
    },
    (err, csv) => {
      downloadCSV(csv, 'quotidien.csv');
    }
  );
};
