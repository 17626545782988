import React, { useEffect, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from 'react-admin';

import { useFormMatchingSeason } from './FormMatchingSeason';
import { useFormReservationOptions } from './useFormReservationOptions';
import { Loadable } from '../../components/Loadable';

function isSameSeason(a, b) {
  return a.label === b.label;
}

const SeasonOptionsForm = ({ close, switchMode }) => {
  const { options, setOptions } = useFormReservationOptions();
  const { isLoading, season } = useFormMatchingSeason();
  const availableOption = useMemo(() => season.options, [season]);
  const state = useMemo(
    () =>
      availableOption.map((option) => ({
        ...option,
        active: options.some((element) => isSameSeason(element, option)),
      })),
    [availableOption, options]
  );

  const addOption = (option) => {
    if (!options.some((element) => isSameSeason(element, option))) {
      options.push(option);
      setOptions(options);
    }
  };

  const removeOption = (option) => {
    const existingOptionIndex = options.findIndex((element) => isSameSeason(element, option));

    if (existingOptionIndex >= 0) {
      options.splice(existingOptionIndex, 1);
      setOptions(options);
    }
  };

  const handleChange = (option) => (event) => {
    if (event.target.checked) {
      addOption(option);
    } else {
      removeOption(option);
    }
  };

  return (
    <>
      <DialogTitle>Prestation de la saison {season?.name}</DialogTitle>
      <DialogContent dividers>
        <Loadable isLoading={isLoading}>
          {state.map((option, index) => (
            <FormGroup key={index}>
              <FormControlLabel
                control={<Checkbox checked={option.active} onChange={handleChange(option)} />}
                label={option.label}
              />
            </FormGroup>
          ))}
        </Loadable>
      </DialogContent>
      <DialogActions>
        <Button label="Prestation personnalisé" onClick={switchMode} />
        <Button label="Fermer" onClick={close} />
      </DialogActions>
    </>
  );
};

const CustomOptionForm = ({ close, switchMode }) => {
  const { options, setOptions } = useFormReservationOptions();
  const [optionLabel, setOptionLabel] = useState('');

  const handleSumbit = () => {
    if (optionLabel && optionLabel.length > 0) {
      options.push({ label: optionLabel, price: 0 });
      setOptions(options);
      close();
    }
  };

  return (
    <>
      <DialogTitle>Ajouter une prestation personnalisé</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Cette nouvelle prestation sera ajouté uniquement à la réservation. Les tarifs ne seront pas mise à jour
          automatiquement.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Nom de la prestation"
          fullWidth
          variant="standard"
          value={optionLabel}
          onChange={(event) => setOptionLabel(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button label="Annuler" onClick={switchMode} />
        <Button label="Ajouter" onClick={handleSumbit} />
      </DialogActions>
    </>
  );
};

const SelectOptionMutliMode = ({ close }) => {
  const [mode, setMode] = useState('season');
  const switchMode = () => setMode(mode === 'season' ? 'custom' : 'season');

  return mode === 'season' ? (
    <SeasonOptionsForm close={close} switchMode={switchMode} />
  ) : (
    <CustomOptionForm close={close} switchMode={switchMode} />
  );
};

export const AddOptionButton = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button label="Ajouter une prestation" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <SelectOptionMutliMode close={handleClose} />
      </Dialog>
    </>
  );
};
