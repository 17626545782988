import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNotify, useRefresh, useRecordContext, useUpdate } from 'react-admin';

import { ButtonWithConfirmation } from '../../components/ButtonWithConfirmation';
import { CANCELED } from '../status';

export const CancelReservationButton = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext(props);
  const [setReservationCancelled, { isLoading }] = useUpdate(
    'reservations',
    { id: record.id, data: { status: CANCELED }, previousData: record },
    {
      onSuccess: () => {
        refresh();
        notify('app.reservation.status_updated_success', { type: 'info' });
      },
      onFailure: () => {
        notify('app.reservation.status_updated_failed', { type: 'warning' });
      },
    },
  );

  return (
    <ButtonWithConfirmation
      variant='contained'
      label='app.reservation.cancel.action'
      startIcon={<CancelIcon />}
      {...props}
      onConfirmed={() => setReservationCancelled()}
      disabled={isLoading || record.status === CANCELED}
      isLoading={isLoading}
      confirmationTitle='app.reservation.cancel.confirmation_title'
      confirmationMessage='app.reservation.cancel.confirmation_message'
    />
  );
};
