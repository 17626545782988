import React from 'react';
import MaterialTextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNotify, useTranslate, usePermissions, useRefresh, useRecordContext, useUpdate } from 'react-admin';
import { SUPER_ADMIN } from '../users/adminTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
  button: {
    marginLeft: 8,
    height: '100%',
  },
}));

export const PaymentCheckExpandPanel = (props) => {
  const record = useRecordContext(props);
  const classes = useStyles();
  const [state, setState] = React.useState(record.transactionComment || '');
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { permissions } = usePermissions();

  const responses = {
    onSuccess: () => {
      refresh();
      notify('app.paymentCheck.update_success', { type: 'success' });
    },
    onFailure: () => {
      notify('app.paymentCheck.update_error', { type: 'warning' });
    },
  };

  const [save, { isLoading: saving }] = useUpdate(
    'reservations',
    { id: record.id, data: { transactionComment: state } },
    responses,
  );

  const [markAsRead, { isLoading: marking }] = useUpdate(
    'reservations',
    {
      id: record.id,
      data: { transactionComment: state, checked: true },
    },
    responses,
  );

  return (
    <div className={classes.root}>
      <MaterialTextField
        variant='outlined'
        style={{ width: '50%' }}
        value={state}
        onChange={(e) => setState(e.target.value)}
      />
      <Button variant='contained' color='primary' className={classes.button} onClick={() => save()} disabled={saving}>
        {translate('app.paymentCheck.save')}
      </Button>
      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        onClick={() => markAsRead()}
        disabled={marking || permissions !== SUPER_ADMIN}
      >
        {translate('app.paymentCheck.markAsRead')}
      </Button>
    </div>
  );
};
