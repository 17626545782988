import React from 'react';
import PropTypes from 'prop-types';
import { TextField, useTranslate, useRecordContext } from 'react-admin';
import { CUSTOMER, COMPANY, TRAVEL_AGENCY } from './userTypes';

/**
 * Format reservation status.
 */
export const UserTypeField = ({ label, source, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  const value = record[source];
  const newRecord = {
    ...record,
  };

  switch (value + '') {
    case '' + CUSTOMER:
      newRecord[source] = translate('app.user.types.customer');
      break;
    case '' + COMPANY:
      newRecord[source] = translate('app.user.types.company');
      break;
    case '' + TRAVEL_AGENCY:
      newRecord[source] = translate('app.user.types.travel_agency');
      break;
    default:
      break;
  }

  return <TextField label={label} source={source} record={newRecord} />;
};

UserTypeField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
};
