import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

import { CANCELED, MISSING_CLIENT, RECEIVED, RETURNED, WAITING_FOR_ACCEPTANCE, WAITING_FOR_DEPOSIT } from './status';
import { COMPANY, CUSTOMER, TRAVEL_AGENCY } from '../users/userTypes';

function formatCurrency(cents) {
  return ((cents || 0) / 100)?.toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    // style: 'currency',
    // currency: 'EUR',
  });
}

function formatStatus(reservation) {
  switch (reservation.status) {
    case CANCELED:
      return 'Annuler';
    case WAITING_FOR_ACCEPTANCE:
      return 'A traiter';
    case WAITING_FOR_DEPOSIT:
      return 'En attente';
    case MISSING_CLIENT:
      return 'Client n\'est pas venue';
    case RECEIVED:
      return 'Recepetionne';
    case RETURNED:
      return 'Rendu';
    default:
      return '';
  }
}

function formatUserType(value) {
  switch (value) {
    case CUSTOMER:
      return 'Client';
    case COMPANY:
      return 'Société';
    case TRAVEL_AGENCY:
      return 'Agence de voyage';
    default:
      return '';
  }
}

function formatProviderId(value) {
  switch (value) {
    case 1:
      return 'AEROPARK';
    case 2:
      return 'AAS';
    default:
      return '';
  }
}

function formatReservation(reservation, optionsLabels) {
  let formattedReservation = {
    id: reservation.id,
    providerId: formatProviderId(reservation.providerId),
    createAt: new Date(reservation.createAt).toLocaleDateString('fr-FR'),
    depositDate: new Date(reservation.deposit).toLocaleDateString('fr-FR'),
    depositTime: new Date(reservation.deposit).toLocaleTimeString('fr-FR'),
    firstname: reservation.firstname,
    lastname: reservation.lastname,
    carBrand: reservation.carBrand,
    registration: reservation.registration,
    reference: reservation.reference,
    destination: reservation.destination,
    pickupDate: new Date(reservation.pickup).toLocaleDateString('fr-FR'),
    pickupTime: new Date(reservation.pickup).toLocaleTimeString('fr-FR'),
    clientName: reservation.clientName,
    clientService: reservation.clientService,
    // Service
    parkDays: reservation.parkDays,
    status: formatStatus(reservation),
    // type
    email: reservation.email,
    HT: formatCurrency(reservation.bonusPrice / 1.2),
    TVA: formatCurrency(reservation.bonusPrice - reservation.bonusPrice / 1.2),
    TTC: formatCurrency(reservation.bonusPrice),
    secondName: reservation.lastname,
    transactionComment: reservation.transactionComment,
    checked: reservation.checked ? 1 : 0,
    clientType: formatUserType(reservation.user_type),
  };

  const options = new Map(reservation.options.map((option) => [option.label, option.price]));

  optionsLabels.forEach((label) => {
    formattedReservation[label] = formatCurrency(options.get(label));
  });

  return formattedReservation;
}

function createHeaders(optionsLabels) {
  return [
    { key: 'id', label: 'N RESERVATION' },
    { key: 'providerId', label: 'SITE' },
    { key: 'depositDate', label: 'DATE DEPOT' },
    { key: 'depositTime', label: 'HEURE DEPOT' },
    { key: 'lastname', label: 'NOM CLIENT' },
    { key: 'firstname', label: 'PRENOM CLIENT' },
    { key: 'carBrand', label: 'VEHICULE' },
    { key: 'registration', label: 'IMMATRICULATION' },
    { key: 'reference', label: 'REFERENCE BROKER' },
    { key: 'destination', label: 'DESTINATION' },
    { key: 'pickupDate', label: 'DATE RETOUR' },
    { key: 'pickupTime', label: 'HEURE RETOUR' },
    { key: 'clientName', label: 'SOCIETE' },
    { key: 'clientService', label: 'SERVICE' },
    { key: 'parkDays', label: 'NBRE DE JOURS' },
    { key: 'status', label: 'STATUT' },
    { key: 'clientType', label: 'TYPE DE CLIENT' },
    { key: 'email', label: 'EMAIL' },
    { key: 'HT', label: 'HT' },
    { key: 'TVA', label: 'TVA' },
    { key: 'TTC', label: 'TTC' },
    { key: 'createAt', label: 'DATE DE FACTURE' },
    { key: 'invoiceId', label: 'N FACTURE' },
    { key: 'secondName', label: 'NOM CLIENT' },
    { key: 'transactionComment', label: 'TRANSACTION' },
    { key: 'checked', label: 'VERIFIE PAR SYLVIE' },
    ...(optionsLabels.map((label) => ({ key: label, label }))),
  ];
}

export const reservationExporter = (reservations) => {
  const optionsLabels = [...new Set(reservations.map((reservation) => reservation.options.map((option) => option.label)).flat())];
  const headers = createHeaders(optionsLabels);

  jsonExport(reservations.map((reservation) => formatReservation(reservation, optionsLabels)),
    {
      headers: headers.map((header) => header.key),
      rename: headers.map((header) => header.label),
      rowDelimiter: ';',
    },
    (err, csv) => downloadCSV(csv, 'reservations'));
};
