import React from 'react';
import { Typography, Box, Divider, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  ReferenceField,
  BooleanField,
  Show,
  Labeled,
  TabbedShowLayout,
  Tab,
  NumberField,
  useRecordContext,
  DateField,
  Toolbar,
  usePermissions,
} from 'react-admin';

import { FullNameField } from '../reservations/FullNameField';
import { ReservationTitle } from '../reservations/ReservationTitle';
import { RemainingPayment } from '../reservations/RemainingPayment';
import { PriceField } from '../components/PriceField';
import { ProviderIdField } from '../components/ProviderIdField';
import { PhoneNumberField } from '../users/PhonesNumberField';
import { ConfirmReservationButton } from './ConfirmReservationButton';
import { SUPER_ADMIN } from '../users/adminTypes';
import { PendingReservationStatusField } from './PendingReservationStatusField';
import { ReservationOptionsTab } from '../reservations/show/ReservationOptionsTab';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  infoRow: {
    marginTop: 16,
    marginBottom: 16,
  },
});

const PromoCode = (props) => {
  const record = useRecordContext(props);

  return (
    <Tooltip title={record.label} placement="bottom">
      <TextField label="app.promoCodes.code" source="code" />
    </Tooltip>
  );
};

const ReservationDetailTab = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Box p="1em">
        {/* Main panel */}
        <Box display="flex">
          <Box flex={3} mr="1em">
            <Typography variant="h6" gutterBottom>
              Détails de la réservation
            </Typography>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.id">
                  <TextField source="id" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.providerId">
                  <ProviderIdField source="providerId" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.pendingReservations.expireAt">
                  <DateField source="expireAt" showTime />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.pendingReservations.status.title">
                  <PendingReservationStatusField source="status" showTime />
                </Labeled>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.createAt">
                  <DateField source="createAt" showTime />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.updatedAt">
                  <DateField source="updatedAt" showTime />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.user.user">
                  <ReferenceField
                    label="User"
                    source="userId"
                    reference="users"
                    link="show"
                    basePath={props.basePath}
                  >
                    <FullNameField label="app.user.name" source="Name" />
                  </ReferenceField>
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.user.email">
                  <ReferenceField
                    label="User"
                    source="userId"
                    reference="users"
                    link="show"
                    basePath={props.basePath}
                  >
                    <TextField label="app.user.email" source="email" />
                  </ReferenceField>
                </Labeled>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.deposit">
                  <DateField source="deposit" showTime />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.depositFlyNumber">
                  <TextField source="depositFlyNumber" />
                </Labeled>
              </Box>
              <Box flex={2} mr="1em">
                <Labeled label="app.reservation.destination">
                  <TextField source="destination" />
                </Labeled>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.pickup">
                  <DateField source="pickup" showTime />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.pickupFlyNumber">
                  <TextField source="pickupFlyNumber" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.origin">
                  <TextField source="origin" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em" />
            </Box>

            <Divider className={classes.infoRow} />

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.firstname">
                  <TextField source="firstname" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.lastname">
                  <TextField source="lastname" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.phoneNumber">
                  <PhoneNumberField source="phoneNumber" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.email">
                  <TextField source="email" />
                </Labeled>
              </Box>
            </Box>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.address">
                  <TextField source="address" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.zipcode">
                  <TextField source="zipcode" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.city">
                  <TextField source="city" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em" />
            </Box>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.carBrand">
                  <TextField source="carBrand" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.registration">
                  <TextField source="registration" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em" />
              <Box flex={1} mr="0.5em" />
            </Box>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.clientName">
                  <TextField source="clientName" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.clientService">
                  <TextField source="clientService" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em" />
              <Box flex={1} mr="0.5em" />
            </Box>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.clientAddress">
                  <TextField source="clientAddress" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.clientZipcode">
                  <TextField source="clientZipcode" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.clientCity">
                  <TextField source="clientCity" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em" />
            </Box>
          </Box>

          {/* Right bar */}
          <Box flex={1} ml="1em">
            <Typography variant="h6" gutterBottom>
              Information Banquaire
            </Typography>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.parkDays">
                  <NumberField source="parkDays" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.parkPrice">
                  <PriceField source="parkPrice" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.checked">
                  <BooleanField source="checked" />
                </Labeled>
              </Box>
            </Box>

            <Box mt="1em" />

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.totalPrice">
                  <PriceField source="totalPrice" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.bonusPrice">
                  <PriceField source="bonusPrice" />
                </Labeled>
              </Box>
              <Box flex={1} ml="0.5em">
                <Labeled label="app.reservation.remainingPrice">
                  <RemainingPayment />
                </Labeled>
              </Box>
            </Box>

            <Box mt="1em" />

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.bankPrice">
                  <PriceField source="bankPrice" />
                </Labeled>
              </Box>
              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.bankError">
                  <NumberField source="bankError" />
                </Labeled>
              </Box>

              <Box flex={1} mr="0.5em">
                <Labeled label="app.reservation.promoCodeId">
                  <ReferenceField
                    label="promoCodeId"
                    source="promoCodeId"
                    reference="promoCodes"
                    link="show"
                    basePath={props.basePath}
                  >
                    <PromoCode />
                  </ReferenceField>
                </Labeled>
              </Box>
            </Box>

            <Box mt="1em" />
            <Divider className={classes.infoRow} />

            <Labeled label="app.reservation.transactionComment">
              <TextField source="transactionComment" />
            </Labeled>

            <Box mt="1em" />
            <Divider className={classes.infoRow} />

            <Box flex={1}>
              <Labeled label="app.reservation.comment">
                <TextField source="comment" multiline="true" />
              </Labeled>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export const ShowPendingReservation = () => {
  const { permissions } = usePermissions();

  return (
    <Show title={<ReservationTitle />}>
      <TabbedShowLayout>
        <Tab label="app.reservation.reservation">
          <ReservationDetailTab />
        </Tab>
        <Tab label="app.reservation.options">
          <ReservationOptionsTab />
        </Tab>
      </TabbedShowLayout>
      <Toolbar>
        <ConfirmReservationButton disabled={permissions !== SUPER_ADMIN} />
      </Toolbar>
    </Show>
  );
};
