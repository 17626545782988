import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CustomerIcon from '@mui/icons-material/PersonAdd';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { useGetList, useTranslate } from 'react-admin';

import { CardIcon } from './CardIcon';

function getDateAWeekAgo() {
  const aWeekAgo = new Date();
  aWeekAgo.setDate(aWeekAgo.getDate() - 7);
  return aWeekAgo.toISOString().substring(0, 10);
}

function useNewUsers() {
  const params = useMemo(() => ({
    pagination: { page: 1, perPage: 20 },
    filter: { 'createAt_date-gte': getDateAWeekAgo() },
    sort: { field: 'id', order: 'DESC' },
  }), []);
  const { data, isLoading } = useGetList('users', params);

  return { users: data || [], isLoading };
}

const useStyles = makeStyles((theme) => ({
  main: {
    flex: '1',
    marginTop: 20,
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right',
  },
  title: {
    padding: '0 16px',
  },
  empty: {
    padding: '0 16px',
    minWidth: 280,
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
    minWidth: 280,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    paddingRight: 0,
  },
}));

export const NewCustomers = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { users } = useNewUsers();

  return (
    <div className={classes.main}>
      <CardIcon Icon={CustomerIcon} bgColor='#4caf50' />
      <Card className={classes.card}>
        <Typography className={classes.title} color='textSecondary'>
          {translate('app.dashboard.newCustomers.title')}
        </Typography>
        <Typography variant='h4' className={users.length === 0 ? classes.empty : classes.value}>
          {users.length}
        </Typography>
        {users.length === 0 ? null : (
          <div>
            <Divider />
            <List>
              {users.map((user) => (
                <ListItem button to={`/users/${user.id}/show`} component={Link} key={user.id}>
                  <ListItemText primary={`${user.firstname} ${user.lastname}`} className={classes.listItemText} />
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </Card>
    </div>
  );
};
