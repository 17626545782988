import React, { useState, useCallback } from 'react';
import { useNotify, useRefresh, useRecordContext, useGetOne, useTranslate } from 'react-admin';
import EmailIcon from '@mui/icons-material/Email';
import DialogContentText from '@mui/material/DialogContentText';

import { ButtonWithConfirmation } from '../../components/ButtonWithConfirmation';
import { getClientTypeTranslationKey } from '../../users/userTypes';
import { Loadable } from '../../components/Loadable';
import { httpClient } from '../../httpClient';

const UserInformation = ({ reservation }) => {
  const translate = useTranslate();
  const { data: user, isLoading } = useGetOne('users', { id: reservation.userId });

  return (
    <DialogContentText>
      <Loadable isLoading={isLoading}>
        {translate('app.reservation.send_confirmation_email.confirmation_message')}
        <br />
        <br />
        {user?.firstname} {user?.lastname}
        <br />
        {user?.email}
        <br />
        <b>{translate(getClientTypeTranslationKey(user?.type))}</b>
      </Loadable>
    </DialogContentText>
  );
};

const ReservationInformation = ({ reservation }) => {
  const translate = useTranslate();

  return (
    <DialogContentText>
      {translate('app.reservation.send_confirmation_email.confirmation_message')}
      <br />
      <br />
      {reservation?.firstname} {reservation?.lastname}
      <br />
      {reservation?.email}
    </DialogContentText>
  );
};

export const SendInvoiceButton = ({ startIcon = <EmailIcon />, ...props }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext(props);
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(() => {
    setLoading(true);
    httpClient(`/reservations/${record.id}/sendInvoice`, {
      method: 'POST',
    })
      .then(() => {
        notify('app.email.send_success', { type: 'success' });
        refresh();
      })
      .catch(() => {
        notify('app.email.send_failed', { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [record]);

  return (
    <ButtonWithConfirmation
      variant='contained'
      label='app.reservation.send_invoice_email.action'
      startIcon={startIcon}
      {...props}
      onConfirmed={handleClick}
      disabled={loading}
      isLoading={loading}
      confirmationTitle='app.reservation.send_invoice_email.confirmation_title'
      confirmationMessage={
        record.userId ? <UserInformation reservation={record} /> : <ReservationInformation reservation={record} />
      }
    />
  );
};
