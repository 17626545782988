import React from 'react';
import { AppBar, Logout, usePermissions, UserMenu, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';

import { SUPER_ADMIN } from '../users/adminTypes';
import { useParking } from '../useParking';
import { Loadable } from '../components/Loadable';

const ChangePasswordMenu = React.forwardRef((props, ref) => {
  const translate = useTranslate();

  return (
    <MenuItem component={Link} ref={ref} {...props} to="/password">
      <ListItemIcon>
        <PasswordIcon />
      </ListItemIcon>
      <ListItemText>{translate('app.app_bar.change_password')}</ListItemText>
    </MenuItem>
  );
});

const MyUserMenu = () => (
  <UserMenu>
    <ChangePasswordMenu />
    <Logout />
  </UserMenu>
);

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
}));

export const CustomAppBar = ({ ...props }) => {
  const classes = useStyles();
  const { parking, isLoading: isLoadingParking } = useParking();
  const { permissions, isLoading: isLoadingPermissions } = usePermissions();

  return (
    <AppBar {...props} elevation={1} userMenu={<MyUserMenu />}>
      <Typography variant="body2" color="inherit" className={classes.title} id="react-admin-title"></Typography>
      <Loadable isLoading={isLoadingParking}>
        <Typography variant="h5" color="inherit">
          {parking?.name}
        </Typography>
      </Loadable>
      <Loadable isLoading={isLoadingPermissions}>
        <Typography variant="body2" color="inherit" className={classes.title} style={{ textAlign: 'right' }}>
          <i>{permissions === SUPER_ADMIN ? 'Super Admin' : 'Admin'}</i>
        </Typography>
      </Loadable>
    </AppBar>
  );
};
