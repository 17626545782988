import React from 'react';
import { useRecordContext } from 'react-admin';

/**
 * Reservation title display in show and edit reservation.
 */
export const ReservationTitle = (props) => {
    const record = useRecordContext(props);

    return <span>Reservation from {record ? `${record.id}` : ''}</span>;
};