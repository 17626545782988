import React from 'react';
import { ChipField, useRecordContext } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';


export const TypeField = ({ source, ...props }) => {
  const record = useRecordContext(props);
  const isPickup = record[source]?.toLowerCase() === 'pickup';
  const reverseDate = new Date(isPickup ? record.deposit : record.pickup);

  return (
    <Tooltip title={reverseDate.toLocaleString()} placement="bottom">
      <div>
        <ChipField source="value" record={{ value: isPickup ? 'R' : 'A' }} />
      </div>
    </Tooltip>
  );
};
