import React from 'react';
import { Button, useListContext, useExpandAll } from 'react-admin';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

export const BulkExpandButton = (props) => {
  const { selectedIds, resource } = useListContext(props);
  const [expanded, toggleExpanded] = useExpandAll(resource, selectedIds);

  const handleClick = () => toggleExpanded();

  return (
    <Button onClick={handleClick} label={expanded ? 'app.buttons.collapse_all' : 'app.buttons.expand_all'}>
      {expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
    </Button>
  );
};
