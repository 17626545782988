import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Confirm, useTranslate, useRecordContext } from 'react-admin';
import DialogContentText from '@mui/material/DialogContentText';

import { useFetch } from '../dashboard/useFetch';

const SaveConfirmationContent = ({}) => {
  const translate = useTranslate();

  return <DialogContentText>{translate('app.pendingReservations.confirmationMessage')}</DialogContentText>;
};

export const ConfirmReservationButton = ({ disabled, ...props }) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  const record = useRecordContext(props);
  const navigate = useNavigate();
  const { data, isLoading, fetch } = useFetch();
  const isConfirmed = record?.status == 1;

  const handleClick = (event) => {
    event.preventDefault();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async (event) => {
    setOpen(false);
    event.stopPropagation();
    fetch(`/pendingReservations/confirm/${record.id}`, { method: 'PUT' });
  };

  useEffect(() => {
    if (data !== null && data !== undefined && data.id) {
      navigate({
        pathname: `/reservations/${data.id}/show`,
      });
    }
  }, [data]);

  return (
    <>
      <Button
        variant="contained"
        style={{ fontSize: 18 }}
        label={translate(
          isConfirmed
            ? 'app.pendingReservations.confirmationButtonAlreadyConfirmed'
            : 'app.pendingReservations.confirmationButton'
        )}
        disabled={disabled || isConfirmed}
        {...props}
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="app.pendingReservations.confirmationButton"
        content={<SaveConfirmationContent />}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
