import React from "react";
import { SelectInput } from "react-admin";
import { withStyles } from '@mui/styles';

export const DefaultStatusInput = (props) => (
  <SelectInput
    choices={[
      { id: "0", name: "app.reservation.status.waitingForAcceptance" },
      { id: "1", name: "app.reservation.status.cancel" },
      { id: "2", name: "app.reservation.status.waitingForDeposit" },
      { id: "3", name: "app.reservation.status.missingClient" },
      { id: "4", name: "app.reservation.status.receive" },
      { id: "5", name: "app.reservation.status.returned" },
    ]}
    {...props}
  />
);

export const StatusInput = withStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
}))(DefaultStatusInput);