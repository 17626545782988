/**
 * Reservation status values.
 */
module.exports = {
    WAITING_FOR_ACCEPTANCE: 0,  // Wait for administrator to confirme
    CANCELED: 1,                // Canceled
    WAITING_FOR_DEPOSIT: 2,     // Wait for the car to arrive
    MISSING_CLIENT: 3,          // Client did not come
    RECEIVED: 4,                // Car has been received and is park
    RETURNED: 5,                // Client got his car back
};