import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export const OptionIconButtonRoot = ({ option, onClick, disabled, isLoading, children, backgroundColor }) => {
  return (
    <Tooltip title={option.label} placement='bottom' leaveDelay={300}>
      <IconButton size='medium' onClick={onClick} disabled={isLoading || disabled} isLoading={isLoading} sx={{
        background: option.active ? 'green' : backgroundColor,
        color: 'white',
        boxShadow: 1,
        "&.Mui-disabled": {
          background: option.active ? 'green' : backgroundColor,
          color: 'white',
          boxShadow: 0,
        }
      }}>
        {isLoading ? <CircularProgress size={24} /> : children}
      </IconButton>
    </Tooltip>
  );
};