import React from 'react';
import { useRecordContext, ReferenceManyField, SingleFieldList, ImageField } from 'react-admin';

const NetworkImage = (props) => {
  const record = useRecordContext(props);

  return (
    <a href={record.url} target="_blank" rel="noopener noreferrer">
      <ImageField source="url" title="id" {...props} />
    </a>
  );
};

const ReservationImage = (props) => {
  const record = useRecordContext(props);

  return (
    <div className="tooltip">
      <NetworkImage source="url" title="id" />
      <span className="tooltiptext">{Date.parse(record.date).toLocaleString()}</span>
    </div>
  );
};

export const ReservationPicturesTab = () => {
  return (
    <ReferenceManyField reference="reservation-pictures" target="id" label="">
      <SingleFieldList linkType={false}>
        <ReservationImage />
      </SingleFieldList>
    </ReferenceManyField>
  );
};
