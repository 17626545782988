import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';

import { SectionTitle } from './SectionTitle';
import { UpdatePricesButton } from './UpdatePricesButton';
import { PriceInput } from '../../components/PriceInput';
import { useFormMatchingSeason } from './FormMatchingSeason';
import { Loadable } from '../../components/Loadable';

const PromoCodeRenderer = (promoCode) => {
  const isAvailable = promoCode && promoCode?.numberOfUsage < promoCode?.maxUse;
  return <span style={isAvailable ? {} : { color: 'red' }}>{promoCode?.code}</span>;
};

const promoCodeLabel = (promoCode) => promoCode?.code;

const TotalOptionsPrice = (props) => {
  const { watch } = useFormContext();
  const translate = useTranslate();
  const options = watch('options');
  const totalPrice = useMemo(() => options?.reduce((sum, option) => sum + option.price, 0) / 100, [options]);

  return (
    <TextField
      {...props}
      label={translate(props.label)}
      value={`${totalPrice.toFixed(2)} €`}
      size='small'
      disabled
      fullWidth
    />
  );
};

export const ReservationPricingInformation = ({ disabledPromoCode, ...props }) => {
  const { isLoading, season } = useFormMatchingSeason();

  return (
    <Box {...props}>
      <SectionTitle label='app.reservation.titles.pricing' />
      <Box display='flex' sx={{ marginTop: 1 }}>
        <Box flex={1} mr='0.5em'>
          <PriceInput label='app.reservation.parkPrice' source='parkPrice' helperText={false} />
        </Box>
        <Box flex={1} ml='0.5em'>
          <TotalOptionsPrice label='app.reservation.totalOptionsPrice' helperText={false} />
        </Box>
      </Box>
      <PriceInput label='app.reservation.totalPrice' source='totalPrice' fullWidth helperText={false}
                  sx={{ marginTop: 1 }} />
      <PriceInput label='app.reservation.bonusPrice' source='bonusPrice' fullWidth helperText={false}
                  sx={{ marginTop: 1 }} />
      <PriceInput label='app.reservation.remainingPrice' source='remainingPrice' fullWidth helperText={false}
                  sx={{ marginTop: 1 }} />
      <PriceInput label='app.reservation.bankPrice' source='bankPrice' fullWidth helperText={false}
                  sx={{ marginTop: 1 }} />
      <ReferenceInput label='app.reservation.promoCodeId' source='promoCodeId' reference='promoCodes'>
        <AutocompleteInput optionText={PromoCodeRenderer} inputText={promoCodeLabel} readOnly={disabledPromoCode}
                           helperText={false} sx={{ marginTop: 1 }} />
      </ReferenceInput>
      <UpdatePricesButton sx={{ marginTop: 1 }} />
      {season && (
        <Box mt={2}>
          <Loadable isLoading={isLoading}>
            <p>
              ⓘ
              <i>
                {' '}
                Le tarif sont calculé sur la base de la saison :{' '}
                <Link to={`/seasons/${season?.id}/show`} underline='none'>
                  {season?.name}
                </Link>
              </i>
            </p>
          </Loadable>
        </Box>
      )}
    </Box>
  );
};
