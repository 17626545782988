import React from 'react';
import { NumberField, useRecordContext } from 'react-admin';


export const PriceField = ({ source, ...props }) => {
  const record = useRecordContext(props);

  return (
    <NumberField
      {...props}
      source={source}
      record={{
        ...record,
        [source]: (record[source] / 100).toFixed(2) + ' €',
      }} />
  );
};
