/**
 * Download functions. 
 */

 /**
 * Download mutilple files.
 * @param {Object[]} files
 * @param {string} file.url
 * @param {string} file.dataType Example: "data:application/pdf;charset=utf8"
 */
export const download = (file) => {
    const link = document.createElement('a');

    link.setAttribute('href', file.url)
    link.setAttribute('rel', 'noopener noreferrer');
    link.setAttribute('target', '_blank');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 * Download mutilple files.
 * @param {Object[]} files
 * @param {string} file.url
 * @param {string} file.dataType Example: "data:application/pdf;charset=utf8"
 */
export const downloadAll = (files) => {
    if (files.length === 0) return;

    const file = files.pop();
    const link = document.createElement('a');

    link.setAttribute('href', file.url)
    link.setAttribute('rel', 'noopener noreferrer');
    link.setAttribute('target', '_blank');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    downloadAll(files);
};