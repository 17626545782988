import React from 'react';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { OptionIconButtonRoot } from './OptionIconButtonRoot';

export function isReportReservation(option) {
  return option.label.toLowerCase().includes('report');
}

export const ReportReservation = (props) => {
  return (
    <OptionIconButtonRoot {...props} backgroundColor='#bdbdbd'>
      <CurrencyExchangeIcon />
    </OptionIconButtonRoot>
  );
};