import React from 'react';
import { SectionTitle } from './SectionTitle';
import { RerservationInformation } from '../CreateReservation/RerservationInformation';
import { TravelerInformation } from '../CreateReservation/TravelerInformation';
import { ProfessionalInformation } from '../CreateReservation/ProfessionalInformation';


export const EditReservationInformationTab = ({ disableChangeUser }) => (
  <div style={{ width: '100%' }}>
    <SectionTitle label="app.reservation.titles.reservation_information" />
    <RerservationInformation />
    <TravelerInformation disableChangeUser={disableChangeUser} />
    <SectionTitle label="app.reservation.titles.professional_information" />
    <ProfessionalInformation />
  </div>
);
