import React, { memo, useState } from 'react';
import { Title, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { httpClient } from '../httpClient';
import { useParking } from '../useParking';
import { useQuery } from 'react-query';

/**
 * Get a color from green to red.
 * @param {*} value from 0 to 1
 */
function getColorBaseOnOccupency(value) {
  const hue = ((1 - value) * 120).toString(10);
  return `hsl(${hue}, 85%, 50%)`;
}

/**
 * Transform API values to fullCalendar events.
 */
function transformToEvents(item, hasParkingMapUrl) {
  return item.reduce((acc, cur) => {
    const max = cur.max === -1 ? cur.defaultMax : cur.max;
    const occupancy = Math.min(cur.quantity / max, 1);
    const itemEvents = [
      {
        date: cur.day,
        color: getColorBaseOnOccupency(occupancy),
        textColor: occupancy < 0.8 ? '#000000' : '#FFFFFF',
        eventType: 'quantity',
        data: cur,
      },
      {
        date: cur.day,
        color: '#95a5a6',
        eventType: 'max',
        data: cur,
      },
    ];

    if (hasParkingMapUrl) {
      itemEvents.push({
        date: cur.day,
        color: '#3498db',
        eventType: 'parking',
        data: cur,
      });
    }

    return acc.concat(itemEvents);
  }, []);
}

function formatQuantityEvent(translate, event) {
  return (
    <>
      <b>{translate('app.days.carParked', { quantity: event.data.quantity })}</b>
      <br />
      <br />
      <i>
        {translate('app.days.deposits', {
          earlyDeposits: event.data.earlyDeposits,
          lateDeposits: event.data.lateDeposits,
        })}
      </i>
      <br />
      <i>
        {translate('app.days.pickups', {
          earlyPickups: event.data.earlyPickups,
          latePickups: event.data.latePickups,
        })}
      </i>
    </>
  );
}

/**
 * Build Fullcalendar event JSX content.
 */
const formatEventContent = (translate) => (arg) => {
  const event = arg.event.extendedProps;

  switch (event.eventType) {
    case 'quantity':
      return formatQuantityEvent(translate, event);

    case 'max':
      return translate('app.days.limitAt', { max: event.data.max });

    case 'parking':
      return 'Parking';

    default:
      return '';
  }
};

/**
 * Trigger on calendar event clicked.
 */
const onEventClicked = (parking, navigate) => (info) => {
  const { eventType, data } = info.event.extendedProps;
  const day = new Date(data.day).toString('yyyy-MM-dd');

  switch (eventType) {
    case 'quantity': {
      navigate({
        pathname: '/daily',
        search: `filter=${JSON.stringify({ date: day })}`,
      });
      break;
    }

    case 'max': {
      navigate(`/days/${data.day}/show`);
      break;
    }

    case 'parking': {
      const filters = escape(`deposit-lte=${day}&pickup-gt=${day}`);
      window.open(`${parking?.parkingMapUrl}?noImages=1&noColors=1&filters=${filters}`, '_blank');
      break;
    }

    // Unknow event type
    default:
      break;
  }
};

const useCalendarEvents = (start, end, parking) => {
  return useQuery(
    ['calendar-events', start, end, parking],
    () => {
      if (start && end && parking) {
        return httpClient(`/calendar?start=${encodeURIComponent(start.toISOString())}&end=${encodeURIComponent(end.toISOString())}`)
          .then((response) => response.json)
          .then((data) => transformToEvents(data, parking.parkingMapUrl));
      } else {
        return [];
      }
    },
  );
};

/**
 * React Calendar component
 */
export const Calendar = memo(() => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { parking } = useParking();
  const [{ start, end }, setPeriod] = useState({ start: null, end: null });
  const { isLoading, data } = useCalendarEvents(start, end, parking);

  const handleDatesChanges = (rangeInfo) => {
    setPeriod({ start: rangeInfo.start, end: rangeInfo.end });
  };

  return (
    <>
      <Title title='app.reservation.reservations' />
      <FullCalendar
        initialView='dayGridMonth'
        editable={false}
        locale='fr'
        displayEventTime={false}
        plugins={[dayGridPlugin]}
        datesSet={handleDatesChanges}
        events={isLoading ? [] : data}
        eventContent={formatEventContent(translate)}
        eventClick={onEventClicked(parking, navigate)}
      />
    </>
  );
});
