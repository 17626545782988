import React from 'react';
import { Show, DateField, SimpleShowLayout, NumberField } from 'react-admin';

import { MaxField } from './MaxField';

export const DayShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <DateField label="app.days.day" source="day" />
      <NumberField label="app.days.quantity" source="quantity" />
      <MaxField label="app.days.max" source="max" />
    </SimpleShowLayout>
  </Show>
);
