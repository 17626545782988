import React from 'react';
import { useRecordContext, TextField } from 'react-admin';

/**
 * Concat firstname and lastname from a user.
 */
export const FullNameField = ({prefix = '', ...props}) => {
  const user = useRecordContext(props);

  return <TextField source="name" record={{ name: `${prefix}${user.firstname} ${user.lastname}` }} />;
};
