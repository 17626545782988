import React, { useMemo } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import { useTranslate } from 'react-admin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { useFetch } from './useFetch';
import { DailyOccupationTable } from './DailyOccupationTable';

function createUrl() {
  const from = new Date();
  const to = new Date().addMonths(14);

  return `/statistics/months?from=${from.toISOString()}&to=${to.toISOString()}`;
}

function formatMonth(month) {
  return { ...month, date: new Date(month.date) };
}

function useMonthsStats() {
  const url = useMemo(createUrl, []);
  const { data, error, isLoading } = useFetch(url);
  const months = useMemo(() => data?.map(formatMonth), [data]);

  return { months: months || [], error, isLoading };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  body: {
    fontSize: 14,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {
    overflowX: 'auto',
  },
  col: {
    paddingRight: 14,
  },
}));

export const MontlyOccupationTable = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { months } = useMonthsStats();
  const [state, setState] = React.useState({
    open: false,
    from: null,
    to: null,
  });

  const onMonthClicked = function (item) {
    const from = new Date(item.date);
    const to = new Date(from.getFullYear(), from.getMonth() + 1, 0);

    setState({ open: true, from, to });
  };

  function handleClose() {
    setState({ ...state, open: false });
  }

  return (
    <div>
      <Paper className={classes.root}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.col}>{translate('app.dashboard.month')}</StyledTableCell>
              <StyledTableCell className={classes.col} align="right">
                {translate('app.dashboard.occupationTable.flux')}
              </StyledTableCell>
              <StyledTableCell className={classes.col} align="right">
                {translate('app.dashboard.occupationTable.incoming')}
              </StyledTableCell>
              <StyledTableCell className={classes.col} align="right">
                {translate('app.dashboard.occupationTable.outgoing')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {months.map((day, index) => (
              <StyledTableRow key={index} onClick={() => onMonthClicked(day)} hover>
                <StyledTableCell className={classes.col} component="th" scope="row">
                  {day.date.toString('MM/yyyy')}
                </StyledTableCell>
                <StyledTableCell className={classes.col} align="right">
                  {day.deposits + day.pickups}
                </StyledTableCell>
                <StyledTableCell className={classes.col} align="right">
                  {day.deposits}
                </StyledTableCell>
                <StyledTableCell className={classes.col} align="right">
                  {day.pickups}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog open={state.open} onClose={handleClose} scroll="paper" aria-labelledby="scroll-dialog-title">
        <DailyOccupationTable from={state.from} to={state.to} />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('app.dashboard.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
