import React, { useMemo } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { useFetch } from './useFetch';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  body: {
    fontSize: 14,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {
    overflowX: 'auto',
  },
  col: {
    paddingRight: 14,
  },
}));

function formatDay(day) {
  return { ...day, date: new Date(day.date) };
}

function useDaysStats(from, to) {
  const url = useMemo(() => `/statistics/days?from=${from.toISOString()}&to=${to.toISOString()}`, [from, to]);
  const { data, error, isLoading } = useFetch(url);
  const days = useMemo(() => data?.map(formatDay), [data]);

  return { days: days || [], error, isLoading };
}

export const DailyOccupationTable = ({
  from,
  to,
  dateFormat = (dateFormat) => dateFormat.toLocaleDateString('fr'),
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const navigate = useNavigate();
  const { days } = useDaysStats(from, to);

  const onElementClick = (item) => {
    const date = new Date(item.date);
    const day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    navigate({
      pathname: '/daily',
      search: `filter=${JSON.stringify({ date: day })}`,
    });
  };

  return (
    <Paper className={classes.root}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell className={classes.col}>{translate('app.dashboard.day')}</StyledTableCell>
            <StyledTableCell className={classes.col} align="right">
              {translate('app.dashboard.occupationTable.flux')}
            </StyledTableCell>
            <StyledTableCell className={classes.col} align="right">
              {translate('app.dashboard.occupationTable.incoming')}
            </StyledTableCell>
            <StyledTableCell className={classes.col} align="right">
              {translate('app.dashboard.occupationTable.outgoing')}
            </StyledTableCell>
            <StyledTableCell className={classes.col} align="right">
              {translate('app.dashboard.occupationTable.o')}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {days.map((day, index) => (
            <StyledTableRow key={index} onClick={() => onElementClick(day)} hover>
              <StyledTableCell className={classes.col} component="th" scope="row">
                {dateFormat(day.date)}
              </StyledTableCell>
              <StyledTableCell className={classes.col} align="right">
                {day.deposits + day.pickups}
              </StyledTableCell>
              <StyledTableCell className={classes.col} align="right">
                {day.deposits}
              </StyledTableCell>
              <StyledTableCell className={classes.col} align="right">
                {day.pickups}
              </StyledTableCell>
              <StyledTableCell className={classes.col} align="right">
                {day.total - day.pickups}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
