import React from 'react';
import { useTranslate, Button } from 'react-admin';
import { Card, CardContent, Box, Typography, CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HomeIcon from '@mui/icons-material/Home';

import publishArticleImage from './welcome_illustration.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    background:
      theme.palette.mode === 'dark'
        ? '#535353'
        : `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,
    color: '#fff',
  },
}));

export const SiteProviderCard = ({ provider }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box display="flex">
          <Box flex="1">
            <Typography variant="h5" component="h2" gutterBottom>
              {translate('app.providers.site.title')}
            </Typography>
            <Box maxWidth="40em">
              <Typography variant="body1" component="p" gutterBottom>
                {translate('app.providers.site.description')}
              </Typography>
            </Box>
            <CardActions
              sx={{
                padding: { xs: 0, xl: null },
                flexWrap: { xs: 'wrap', xl: null },
                '& a': {
                  marginTop: { xs: '1em', xl: null },
                  marginLeft: { xs: '0!important', xl: null },
                  marginRight: { xs: '1em', xl: null },
                },
              }}
            >
              <Button
                variant="contained"
                href={provider.metadata}
                startIcon={<HomeIcon />}
                label={`Site web de ${provider.name}`}
              />
            </CardActions>
          </Box>
          <Box
            display={{ xs: 'none', sm: 'none', md: 'block' }}
            sx={{
              background: `url(${publishArticleImage}) top right / cover`,
              marginLeft: 'auto',
            }}
            width="16em"
            height="9em"
            overflow="hidden"
          />
        </Box>
      </CardContent>
    </Card>
  );
};
