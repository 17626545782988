import React from 'react';
import { ChipField, useRecordContext } from 'react-admin';


export const StockField = ({ source, ...props }) => {
  const record = useRecordContext(props);

  if (record[source] === '' || record[source] == null) {
    return <span></span>;
  } else {
    return <ChipField {...props} source={source} />;
  }
};
