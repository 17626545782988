/**
 * User type values.
 */
export const CUSTOMER = 0;
export const COMPANY = 1;
export const TRAVEL_AGENCY = 2;

export const getClientTypeTranslationKey = (type) => {
  switch (type) {
    case CUSTOMER:
      return 'app.user.types.customer';
    case COMPANY:
      return 'app.user.types.company';
    case TRAVEL_AGENCY:
      return 'app.user.types.travel_agency';
    default:
      return '';
  }
};

const all = {
  CUSTOMER,
  COMPANY,
  TRAVEL_AGENCY,
};

export default all;
