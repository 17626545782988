import React from 'react';
import { useRecordContext } from 'react-admin';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { PriceField } from '../../components/PriceField';
import { OptionIconButton } from '../options/OptionIconButton';

export const ReservationOptionsTab = (props) => {
  const record = useRecordContext(props);
  const options = record.options || [];

  return (
    <List>
      {options.map((option, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <OptionIconButton index={index} option={option} />
          </ListItemAvatar>
          <ListItemText primary={option.label} secondary={option.description} />
          <PriceField record={option} source='price' />
        </ListItem>
      ))}
    </List>
  );
};
