import React from 'react';
import EuroIcon from '@mui/icons-material/Euro';

import { OptionIconButtonRoot } from './OptionIconButtonRoot';

export function isInsurance(option) {
  const label = option.label.toLowerCase();
  return label.includes('assurance') || label.includes('insurance');
}

export const Insurance = (props) => {
  return (
    <OptionIconButtonRoot {...props} backgroundColor='#bdbdbd'>
      <EuroIcon />
    </OptionIconButtonRoot>
  );
};