import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useRecordContext } from 'react-admin';

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  button: {
    marginLeft: 8,
    marginRight: 8,
  },
}));

export const UserExpandPanel = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const record = useRecordContext(props);

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() =>
          navigate('/reservations/create', {
            state: { user: record },
          })
        }
      >
        Ajouter une réservation
      </Button>
    </div>
  );
};
