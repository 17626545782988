import React from 'react';
import 'datejs';
import {
  List,
  Datagrid,
  ShowButton,
  TextField,
  TextInput,
  DateField,
  Pagination,
  DateInput,
  NumberInput,
  FilterButton,
  TopToolbar,
} from 'react-admin';

import { ReservationUserLink } from '../reservations/ReservationUserField';
import { ProviderIdInput } from '../components/ProviderIdInput';
import { ReservationProviderAvatarField } from '../reservations/ReservationProviderAvatarField';

const filters = [
  <DateInput label="app.reservation.depositGte" source="deposit-gte" alwaysOn />,
  <DateInput label="app.reservation.depositLte" source="deposit-lte" alwaysOn />,
  <ProviderIdInput label="app.reservation.providerId" source="providerId" alwaysOn />,
  <TextInput label="app.reservation.email" source="email" alwaysOn />,
  <TextInput label="app.reservation.lastname" source="lastname" alwaysOn />,
  <NumberInput label="app.reservation.id" source="id" />,
  <TextInput label="app.reservation.firstname" source="firstname" />,
  <DateInput label="app.reservation.deposit" source="deposit" />,
  <DateInput label="app.reservation.pickup" source="pickup" />,
  <TextInput label="app.reservation.clientName" source="clientName" />,
  <TextInput label="app.reservation.phoneNumber" source="phoneNumber" />,
  <TextInput label="app.reservation.carBrand" source="carBrand" />,
  <TextInput label="app.reservation.registration" source="registration" />,
];

const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
    </TopToolbar>
  );
};

export const ListPendingReservation = () => {
  return (
    <List
      title="app.pendingReservations.title"
      filters={filters}
      exporter={false}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      filter={{ status: 0 }}
    >
      <Datagrid rowClick="expand" bulkActionButtons={false}>
        <ReservationProviderAvatarField label="app.reservation.providerId" />
        <TextField label="app.reservation.id" source="id" />
        <DateField label="app.reservation.createAt" source="createAt" showTime />
        <DateField label="app.pendingReservations.expireAt" source="expireAt" showTime />
        <ReservationUserLink label="app.user.user" />
        <DateField label="app.reservation.deposit" source="deposit" showTime />
        <DateField label="app.reservation.pickup" source="pickup" showTime />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
