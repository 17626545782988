import React from 'react';
import Typography from '@mui/material/Typography';
import { useRecordContext } from 'react-admin';


export const FlyField = (props) => {
  const record = useRecordContext(props);

  return (
    <div>
      <Typography component="span" variant="body2">
        {record.fly}
      </Typography>
      <Typography component="span" variant="body2">
        {record.place}
      </Typography>
    </div>
  );
};
