import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

import {
    CUSTOMER,
    COMPANY,
    TRAVEL_AGENCY
} from './userTypes';

function formatUserType(value) {
    switch (value) {
        case CUSTOMER: return "Client";
        case COMPANY: return "Société";
        case TRAVEL_AGENCY: return "Agence de voyage";
        default: return "";
    }
}

export const userExporter = users => {
    jsonExport(users.map(user => {
        return {
            type: formatUserType(user.type),
            lastname: user.lastname,
            firstname: user.firstname,
            mobile: user.mobile,
            email: user.email,
            address: user.address,
            zipcode: user.zipcode,
            city: user.city,
            clientName: user.clientName,
            clientService: user.clientService,
            clientAddress: user.clientAddress,
            clientZipcode: user.clientZipcode,
            clientCity: user.clientCity
        }
    }), {
        headers: [
            'type',
            'lastname',
            'firstname',
            'mobile',
            'email',
            'address',
            'zipcode',
            'city',
            'clientName',
            'clientService',
            'clientAddress',
            'clientZipcode',
            'clientCity',
        ],
        rename: [
            'Type de client',
            'Nom',
            'Prénom',
            'Téléphone',
            'Email',
            'Adresse',
            'Code Postal',
            'Ville',
            'Société',
            'Service',
            'Adresse société',
            'Code postal société',
            'Ville société',
        ],
        rowDelimiter: ';',
    }, (err, csv) => {
        downloadCSV(csv, 'utilisateurs');
    });
};