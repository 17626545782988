import React from 'react';
import { useTranslate } from 'react-admin';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { format, subDays, addDays } from 'date-fns';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { useProviderRevenue } from './useProviderRevenue';

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date) => new Date(date).toLocaleDateString();

const aggregateByDay = (orders) =>
  orders.reduce((acc, curr) => {
    const day = format(new Date(curr.date), 'yyyy-MM-dd');
    if (!acc[day]) {
      acc[day] = 0;
    }
    acc[day] += curr.total;
    return acc;
  }, {});

const getRevenuePerDay = (orders) => {
  const daysWithRevenue = aggregateByDay(orders);
  return lastMonthDays.map((date) => ({
    date: date.getTime(),
    total: daysWithRevenue[format(new Date(date), 'yyyy-MM-dd')] / 100 || 0,
  }));
};

export const ProviderGraph = ({ provider }) => {
  const translate = useTranslate();
  const { revenuePerDay, isLoading } = useProviderRevenue(provider.id);

  return (
    <Card>
      <CardHeader title={translate('app.providers.revenue.30DaysRevenue')} />
      <CardContent>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <AreaChart data={isLoading ? [] : getRevenuePerDay(revenuePerDay)}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                name="Date"
                type="number"
                scale="time"
                domain={[addDays(aMonthAgo, 1).getTime(), new Date().getTime()]}
                tickFormatter={dateFormatter}
              />
              <YAxis dataKey="total" name="Revenue" unit="€" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                formatter={(value) =>
                  new Intl.NumberFormat(undefined, {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(value)
                }
                labelFormatter={(label) => dateFormatter(label)}
              />
              <Area type="monotone" dataKey="total" stroke="#8884d8" strokeWidth={2} fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};
