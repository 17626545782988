import React from 'react';
import { required, SelectInput, TextInput } from 'react-admin';
import { SectionTitle } from './SectionTitle';
import { ProviderIdInput } from '../../components/ProviderIdInput';

export const ReservationInternalInformation = () => {
  return (
    <>
      <SectionTitle label='app.reservation.titles.internal_information' />
      <ProviderIdInput source='providerId' fullWidth validate={[required()]} helperText={false}
                       sx={{ marginTop: 1 }} />
      <SelectInput
        label='app.reservation.status.title'
        source='status'
        fullWidth
        validate={[required()]}
        choices={[
          {
            id: '0',
            name: 'app.reservation.status.waitingForAcceptance',
          },
          { id: '1', name: 'app.reservation.status.cancel' },
          {
            id: '2',
            name: 'app.reservation.status.waitingForDeposit',
          },
          { id: '3', name: 'app.reservation.status.missingClient' },
          { id: '4', name: 'app.reservation.status.receive' },
          { id: '5', name: 'app.reservation.status.returned' },
        ]}
        defaultValue='0'
        helperText={false}
        sx={{ marginTop: 1 }}
      />
      <TextInput label='app.reservation.stock' source='stock' fullWidth helperText={false} sx={{ marginTop: 1 }} />
      <TextInput label='app.reservation.transactionComment' source='transactionComment' multiline fullWidth
                 helperText={false} sx={{ marginTop: 1 }} />
      <TextInput label='app.reservation.comment' source='comment' fullWidth multiline helperText={false}
                 sx={{ marginTop: 1 }} />
    </>
  );
};
