import React, { useMemo } from 'react';
import { useRecordContext, TextField } from 'react-admin';

export const formatNumber = (number) => {
  return (number || "")
    .replace(/[\D]/g, '')
    .replace(/(^33)/g, '0')
    .replace(/^((?!33|0))/g, '+$1');
};

export const PhoneNumberField = ({ source, props }) => {
  const record = useRecordContext(props);
  const phoneNumber = useMemo(() => formatNumber(record[source]), [record]);

  return <TextField {...props} source={source} record={{ [source]: phoneNumber }} />;
};

// TODO: Deprecated
export const PhonesNumberField = (props) => {
  const record = useRecordContext(props);

  return (
    <span>
      {record.phone}
      {record.phone && record.mobile ? ' / ' : ''}
      {record.mobile}
    </span>
  );
};
