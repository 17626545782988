import React from 'react';
import { TextField, useTranslate, useRecordContext } from 'react-admin';

export const PendingReservationStatusField = ({ label, source, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  const value = record[source];
  const newRecord = {
    ...record,
  };

  switch (value) {
    case 0:
      newRecord[source] = translate('app.pendingReservations.status.notConfirmed');
      break;
    case 1:
      newRecord[source] = translate('app.pendingReservations.status.confirmed');
      break;
    default:
      break;
  }

  return <TextField label={label} source={source} record={newRecord} />;
};
