import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecordContext } from 'react-admin';

export const useFormOptions = () => {
  const { watch, setValue } = useFormContext();
  const options = watch('options');

  const setOptions = (value) => {
    setValue('options', value, { shouldDirty: true });
  };

  useEffect(() => {
    if (options == null || options == undefined) {
      setValue('options', []);
    }
  }, [options]);

  return useMemo(() => ({ options: options || [], setOptions }), [options, setOptions]);
};

export const useRecordOptions = (props) => {
  const record = useRecordContext(props);

  return useMemo(() => record.options || [], [record]);
};
