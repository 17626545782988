import React from 'react';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';

import { OptionIconButtonRoot } from './OptionIconButtonRoot';

export function isCleaning(option) {
  const label = option.label.toLowerCase();
  return label.includes('lavage') || label.includes('clean');
}

export const Cleaning = (props) => {
  return (
    <OptionIconButtonRoot {...props} backgroundColor='#2980b9'>
      <LocalCarWashIcon />
    </OptionIconButtonRoot>
  );
};