import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import { DirectionsCar } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useTranslate } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';

import { useFetch } from './useFetch';
import { CardIcon } from './CardIcon';

function useNbrCarParked() {
  const url = useMemo(() => `/statistics/carparked`, []);
  const { data, error, isLoading } = useFetch(url);

  return { nbrCarParked: data?.nbrCarParked, error, isLoading };
}

const useStyles = makeStyles(() => ({
  main: {
    flex: '1',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
}));

export const NumberOfCarParked = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { nbrCarParked } = useNbrCarParked();

  return (
    <div className={classes.main}>
      <CardIcon Icon={DirectionsCar} bgColor="#9b59b6" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate('app.dashboard.nbCarParked.title')}
        </Typography>
        <Tooltip title={translate('app.dashboard.nbCarParked.tooltip')} placement="bottom">
          <Typography variant="h4">{nbrCarParked}</Typography>
        </Tooltip>
      </Card>
    </div>
  );
};
