import React, { useEffect, useState, useCallback } from 'react';
import { Button, useListContext } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const LazyScrollPagination = ({ loadMoreLabel, noMoreLabel, batchSize, ...props }) => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const { setPerPage, isLoading, isFetching, perPage, hasNextPage } = useListContext(props);
  const isBusy = isLoading || isFetching;

  // console.log('## LazyPagination ##');
  // console.log('isAtBottom', isAtBottom);
  // console.log('isLoading', isLoading);
  // console.log('isFetching', isFetching);
  // console.log('perPage', perPage);
  // console.log('hasNextPage', hasNextPage);

  useEffect(() => {
    function onScroll() {
      setIsAtBottom(window.innerHeight + window.scrollY >= document.body.offsetHeight);
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (perPage < batchSize) {
      setPerPage(batchSize);
    } else if (isAtBottom && !isBusy && hasNextPage) {
      // console.log('Load more from scroll');
      setPerPage(perPage + batchSize);
    }
  }, [perPage, setPerPage, isAtBottom, isBusy, hasNextPage]);

  const moreButtonClicked = useCallback(() => {
    // console.log('Load more from click');
    setPerPage(perPage + batchSize);
  }, [setPerPage]);

  return (
    <Box>
      <Button
        variant="contained"
        fullWidth
        color="primary"
        style={{ fontSize: 18, paddingTop: 16, paddingBottom: 16 }}
        disabled={isBusy || !hasNextPage}
        label={hasNextPage ? loadMoreLabel : noMoreLabel}
        onClick={moreButtonClicked}
      >
        {isBusy ? <CircularProgress color="secondary" /> : null}
      </Button>
    </Box>
  );
};
