import React, { useEffect, useMemo } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { CreateBase, Form, FormTab, SaveButton, TabbedFormView, Title, Toolbar } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { FormMatchingSeasonContextProvider } from '../EditReservation/FormMatchingSeason';
import { EditReservationInformationTab } from '../EditReservation/EditReservationInformationTab';
import { EditReservationOptionsTab } from '../EditReservation/EditReservationOptionsTab';
import { ReservationPricingInformation } from '../EditReservation/ReservationPricingInformation';
import { ReservationInternalInformation } from '../EditReservation/ReservationInternalInformation';

export const UserRenderer = (choice) => `${choice.firstname || ''} ${choice.lastname || ''}`;

const CreateReservationToolbar = () => (
  <Toolbar>
    <Box display='flex' justifyContent='space-between' width='100%'>
      <SaveButton />
    </Box>
  </Toolbar>
);

const LoadLocationUser = () => {
  const location = useLocation();
  const userFromLocationUser = useMemo(() => location.state?.user, [location]);
  const { reset } = useFormContext();

  useEffect(() => {
    if (userFromLocationUser?.id != null) {
      reset({ userId: userFromLocationUser?.id });
    }
  }, [reset, userFromLocationUser]);

  return <div></div>;
};

export const CreateReservation = () => {
  return (
    <CreateBase redirect='show'>
      <Title title='app.reservation.create-title' />
      <Form>
        <FormMatchingSeasonContextProvider autoAddMandantoryOptions>
          <Box display='flex' style={{ marginTop: '1em' }}>
            <Box flex={3} mr='0.5em'>
              <Card>
                <TabbedFormView toolbar={<CreateReservationToolbar />}>
                  <FormTab label='app.reservation.reservation'>
                    <EditReservationInformationTab />
                  </FormTab>
                  <FormTab label='app.reservation.options'>
                    <EditReservationOptionsTab />
                  </FormTab>
                </TabbedFormView>
              </Card>
            </Box>

            <Box flex={1} ml='0.5em'>
              <Card>
                <CardContent>
                  <ReservationInternalInformation />
                  <ReservationPricingInformation />
                </CardContent>
              </Card>
            </Box>
          </Box>
        </FormMatchingSeasonContextProvider>
        <LoadLocationUser />
      </Form>
    </CreateBase>
  );
};
