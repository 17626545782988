import React from 'react';
import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

// TODO: use this everywhere
export const SectionTitle = ({ label, children }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
      {children}
    </Typography>
  );
};
