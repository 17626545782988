import React, { useMemo } from 'react';
import { BooleanInput, DateInput, FormTab, required, SelectInput, TextInput } from 'react-admin';

import { useParking } from '../../useParking';
import { Loadable } from '../../components/Loadable';

const SeasonProviderIdInput = (props) => {
  const { parking, isLoading } = useParking();
  const choices = useMemo(() => parking?.reservationProviders || [], [parking]);

  return (
    <Loadable isLoading={isLoading}>
      <SelectInput
        choices={choices}
        emptyText='app.season.providerId.none'
        resettable={true}
        {...props}
      />
    </Loadable>
  );
};

export const InformationTab = (props) => (
  <FormTab {...props} label='app.season.information'>
    <SeasonProviderIdInput label='app.season.providerId.title' source='providerId' />
    <TextInput label='app.season.name' source='name' validate={required()} />
    <DateInput label='app.season.beginAt' source='beginAt' validate={required()} />
    <DateInput label='app.season.endAt' source='endAt' validate={required()} />
    <BooleanInput label='app.season.isDefault' source='isDefault' />
  </FormTab>
);
