import React from 'react';
import { SelectInput } from 'react-admin';
import { withStyles } from '@mui/styles';

const DefaultDailyReservationTypeInput = (props) => (
  <SelectInput
    choices={[
      { id: 'deposit', name: 'app.reservation.type.deposit' },
      { id: 'pickup', name: 'app.reservation.type.pickup' },
    ]}
    emptyText='app.reservation.type.all'
    resettable={true}
    {...props}
  />
);

export const DailyReservationTypeInput = withStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
}))(DefaultDailyReservationTypeInput);
