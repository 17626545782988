import { Warning } from '@mui/icons-material';

import { ListPendingReservation } from './ListPendingReservation';
import { ShowPendingReservation } from './ShowPendingReservation';

export default {
  icon: Warning,
  list: ListPendingReservation,
  show: ShowPendingReservation,
};
