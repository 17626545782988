import { ViewDay } from '@mui/icons-material';

import { DayShow } from './DayShow';
import { DayEdit } from './DayEdit';
import { Calendar } from './Calendar';

export default {
  icon: ViewDay,
  show: DayShow,
  edit: DayEdit,
  list: Calendar,
};
