import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useRecordContext } from 'react-admin';

export const FlyTrackerField = ({ source, ...props }) => {
  const [info, setInfo] = useState('');
  const record = useRecordContext(props);
  let fly = record[source];
  let departure = '';

  if (record.type === 'deposit') {
    departure = '/departs';
  }

  if (fly && fly.length >= 6) {
    fly = fly.slice(0, 6);
  }

  // TODO: useCallback
  const onTooltipOpen = () => {
    if (info.length === 0) {
      fetch(`https://www.nantes.aeroport.fr/fr/vols-en-temps-reel${departure}?flight_number=${fly}`)
        .then((response) => {
          return response.text();
        })
        .then((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');
          const elements = doc.getElementsByClassName('status__flight');

          if (elements && elements.length > 0) {
            setInfo(elements[0].innerText.trim());
          }
        });
    }
  };

  return (
    <Tooltip title={info} placement="bottom" onOpen={onTooltipOpen}>
      <a
        href={`https://www.nantes.aeroport.fr/fr/vols-en-temps-reel${departure}?flight_number=${fly}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record[source]}
      </a>
    </Tooltip>
  );
};
