import React from 'react';
import { TabbedShowLayout, Tab, Show } from 'react-admin';

import { UserTitle } from '../UserTitle';
import { UserInformation } from './UserInformation';
import { UserReservations } from './UserReservations';
import { UsedPromoCodes } from './UsedPromoCodes';
import { UnusedPromoCodes } from './UnusedPromoCodes';

export const ShowUser = () => (
  <Show title={<UserTitle />}>
    <TabbedShowLayout>
      <Tab label="app.user.user">
        <UserInformation />
      </Tab>
      <Tab label="app.reservation.reservations" path="reservations">
        <UserReservations />
      </Tab>
      {/* TODO: Fix api routes first */}
      {/*<Tab label="app.reservation.usedPromoCodes" path="usedPromoCodes">*/}
      {/*  <UsedPromoCodes />*/}
      {/*</Tab>*/}
      {/*<Tab label="app.reservation.assignedPromoCodes" path="assignedPromoCodes">*/}
      {/*  <UnusedPromoCodes />*/}
      {/*</Tab>*/}
    </TabbedShowLayout>
  </Show>
);
