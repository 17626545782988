import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useFetch } from '../../dashboard/useFetch';
import { useFormReservationOptions } from './useFormReservationOptions';

const FormMatchingSeasonContextClass = createContext({
  isLoading: false,
  season: null,
  error: null,
});

function isSameOption(a, b) {
  return a.label === b.label;
}

export const useFormMatchingSeason = () => {
  return useContext(FormMatchingSeasonContextClass);
};

function useFormMatchingSeasonHook() {
  const { watch } = useFormContext();
  const deposit = watch('deposit');
  const pickup = watch('pickup');
  const providerId = watch('providerId');
  const url = useMemo(() => {
    return deposit && pickup && providerId
      ? `/seasons/find?deposit=${deposit}&pickup=${pickup}&providerId=${providerId}`
      : null;
  });

  return useFetch(url);
}

export const FormMatchingSeasonContextProvider = ({ children, autoAddMandantoryOptions }) => {
  const { options, setOptions } = useFormReservationOptions();
  const { data, error, isLoading } = useFormMatchingSeasonHook();

  useEffect(() => {
    if (autoAddMandantoryOptions && data && data.options) {
      let hasChanged = false;
      for (const option of data.options) {
        if (option.defaultValue === true && !options.some((element) => isSameOption(element, option))) {
          options.push(option);
          hasChanged = true;
        }
      }
      if (hasChanged === true) {
        setOptions(options);
      }
    }
  }, [data]);

  const context = useMemo(
    () => ({
      isLoading,
      season: data,
      error: error,
    }),
    [isLoading, data, error]
  );

  return <FormMatchingSeasonContextClass.Provider value={context}>{children}</FormMatchingSeasonContextClass.Provider>;
};
