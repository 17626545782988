import React from 'react';
import { Box } from '@mui/material';
import { TextInput, required } from 'react-admin';
import { DateTimeInput } from '../../components/DateTimeInput';

export const RerservationInformation = () => (
  <>
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <DateTimeInput
          label="app.reservation.deposit"
          source="deposit"
          resource="reservation"
          fullWidth
          validate={[required()]}
          parse={(value) => value}
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <DateTimeInput
          label="app.reservation.pickup"
          source="pickup"
          resource="reservation"
          fullWidth
          validate={[required()]}
          parse={(value) => value}
        />
      </Box>
    </Box>

    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <TextInput
          label="app.reservation.depositFlyNumber"
          source="depositFlyNumber"
          resource="reservation"
          fullWidth
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <TextInput label="app.reservation.pickupFlyNumber" source="pickupFlyNumber" resource="reservation" fullWidth />
      </Box>
    </Box>

    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <TextInput label="app.reservation.destination" source="destination" resource="reservation" fullWidth />
      </Box>
      <Box flex={1} ml="0.5em">
        <TextInput label="app.reservation.origin" source="origin" resource="reservation" fullWidth />
      </Box>
    </Box>
  </>
);
