import React from 'react';
import { Edit, SimpleForm, DateInput, NumberInput, Toolbar, SaveButton } from 'react-admin';

const DayEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const DayEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<DayEditToolbar />}>
      <DateInput label="app.days.day" source="day" readOnly />
      <NumberInput label="app.days.quantity" source="quantity" readOnly />
      <NumberInput label="app.days.max" source="max" />
    </SimpleForm>
  </Edit>
);
