import React from 'react';
import { useTranslate } from 'react-admin';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  card: {
    background: `linear-gradient(to right, #535353 0%, #757474 35%), linear-gradient(to bottom, #757474 0%, #535353 50%), #535353`,
    color: '#fff',
  },
}));

export const ParkcloudProviderCard = () => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box display="flex">
          <Box flex="1">
            <Typography variant="h5" component="h2" gutterBottom>
              {translate('app.providers.parkcloud.title')}
            </Typography>
            <Box maxWidth="40em">
              <Typography variant="body1" component="p" gutterBottom>
                {translate('app.providers.parkcloud.description')}
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ xs: 'none', sm: 'none', md: 'block' }}
            sx={{
              background: `url(/api-icon.png)`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              marginLeft: 'auto',
            }}
            width="9em"
            height="9em"
            overflow="hidden"
          />
        </Box>
      </CardContent>
    </Card>
  );
};
