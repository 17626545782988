import React from 'react';
import { TextField, useRecordContext } from 'react-admin';
import Link from '@mui/material/Link';

import { FullNameField } from './FullNameField';
import { COMPANY, TRAVEL_AGENCY } from '../users/userTypes';

const ReservationUserDisplayName = ({ reservation }) => {
  if (reservation.user_type === COMPANY) {
    return (
      <TextField
        label="app.user.clientName"
        source="name"
        record={{ name: reservation.user_clientName }}
        emptyText="(Société)"
      />
    );
  }

  if (reservation.user_type === TRAVEL_AGENCY) {
    return (
      <TextField
        label="app.user.clientName"
        source="name"
        record={{ name: reservation.user_clientName }}
        emptyText="(Agence de voyage)"
      />
    );
  }

  return (
    <FullNameField
      record={{ firstname: reservation.user_firstname, lastname: reservation.user_lastname }}
      label="app.user.name"
      emptyText="(Utilisateur)"
    />
  );
};

export const ReservationUserLink = () => {
  const reservation = useRecordContext();

  if (reservation.userId === undefined || reservation.userId === null) {
    return <></>;
  }

  return (
    <Link href={`#/users/${reservation.userId}/show`} underline="none">
      <ReservationUserDisplayName reservation={reservation} />
    </Link>
  );
};
