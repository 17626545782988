import React from 'react';
import { Show, TabbedShowLayout, Tab } from 'react-admin';

import { ReservationTitle } from '../ReservationTitle';
import { ReservationDetailTab } from './ReservationDetailTab';
import { ReservationOptionsTab } from './ReservationOptionsTab';
import { ReservationPicturesTab } from './ReservationPicturesTab';

export const ShowReservation = () => {
  return (
    <Show title={<ReservationTitle />}>
      <TabbedShowLayout>
        <Tab label="app.reservation.reservation">
          <ReservationDetailTab />
        </Tab>
        <Tab label="app.reservation.options">
          <ReservationOptionsTab />
        </Tab>
        <Tab label="app.reservation.pictures">
          <ReservationPicturesTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
