import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { DateField, Labeled, NumberField, ReferenceField, TextField } from 'react-admin';
import { FullNameField } from '../FullNameField';
import { StatusField } from '../StatusField';
import { PhoneNumberField } from '../../users/PhonesNumberField';
import { Ticket } from './Ticket';

export const ReservationDetailTab = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={9} sm={8} xs={12}>
          <MainPanel />
        </Grid>

        {/* Right bar */}
        <Grid item md={3} sm={4} xs={12}>
          <Ticket />
          <Box mt='1em' />
          <Divider />
          <Box mt='1em' />
          <Labeled label='app.reservation.transactionComment'>
            <TextField source='transactionComment' />
          </Labeled>
          <Box flex={1} mr='0.5em'>
            <Labeled label='app.reservation.bankError'>
              <NumberField source='bankError' />
            </Labeled>
          </Box>
          <Box mt='1em' />
          <Divider />
          <Box mt='1em' />
          <Box flex={1}>
            <Labeled label='app.reservation.comment'>
              <TextField source='comment' multiline='true' />
            </Labeled>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

const MainPanel = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Détails de la réservation
        </Typography>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.createAt'>
          <DateField source='createAt' showTime />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.updatedAt'>
          <DateField source='updatedAt' showTime />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.status.title'>
          <StatusField source='status' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.stock'>
          <TextField source='stock' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.user.user'>
          <ReferenceField
            label='User'
            source='userId'
            reference='users'
            link="show"
            basePath={props.basePath}
          >
            <FullNameField label='app.user.name' source='Name' />
          </ReferenceField>
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.user.email'>
          <ReferenceField
            label='User'
            source='userId'
            reference='users'
            link="show"
            basePath={props.basePath}
          >
            <TextField label='app.user.email' source='email' />
          </ReferenceField>
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.reference'>
          <TextField source='reference' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.promoCodeId'>
          <ReferenceField
            label='promoCodeId'
            source='promoCodeId'
            reference='promoCodes'
            link="show"
            basePath={props.basePath}
          >
            <TextField label='app.promoCodes.code' source='code' />
          </ReferenceField>
        </Labeled>
      </Grid>
      <Grid item md={4} xs={6}>
        <Labeled label='app.reservation.deposit'>
          <DateField source='deposit' showTime />
        </Labeled>
      </Grid>
      <Grid item md={4} xs={3}>
        <Labeled label='app.reservation.depositFlyNumber'>
          <TextField source='depositFlyNumber' />
        </Labeled>
      </Grid>
      <Grid item md={4} xs={3}>
        <Labeled label='app.reservation.destination'>
          <TextField source='destination' />
        </Labeled>
      </Grid>
      <Grid item md={4} xs={6}>
        <Labeled label='app.reservation.pickup'>
          <DateField source='pickup' showTime />
        </Labeled>
      </Grid>
      <Grid item md={4} xs={3}>
        <Labeled label='app.reservation.pickupFlyNumber'>
          <TextField source='pickupFlyNumber' />
        </Labeled>
      </Grid>
      <Grid item md={4} xs={3}>
        <Labeled label='app.reservation.origin'>
          <TextField source='origin' />
        </Labeled>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.firstname'>
          <TextField source='firstname' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.lastname'>
          <TextField source='lastname' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.phoneNumber'>
          <PhoneNumberField source='phoneNumber' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.email'>
          <TextField source='email' />
        </Labeled>
      </Grid>
      <Grid item md={6} xs={6}>
        <Labeled label='app.reservation.address'>
          <TextField source='address' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={3}>
        <Labeled label='app.reservation.zipcode'>
          <TextField source='zipcode' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={3}>
        <Labeled label='app.reservation.city'>
          <TextField source='city' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.carBrand'>
          <TextField source='carBrand' />
        </Labeled>
      </Grid>
      <Grid item md={3} xs={6}>
        <Labeled label='app.reservation.registration'>
          <TextField source='registration' />
        </Labeled>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Labeled label='app.reservation.clientName'>
          <TextField source='clientName' />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label='app.reservation.clientService'>
          <TextField source='clientService' />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label='app.reservation.clientAddress'>
          <TextField source='clientAddress' />
        </Labeled>
      </Grid>
      <Grid item xs={3}>
        <Labeled label='app.reservation.clientZipcode'>
          <TextField source='clientZipcode' />
        </Labeled>
      </Grid>
      <Grid item xs={3}>
        <Labeled label='app.reservation.clientCity'>
          <TextField source='clientCity' />
        </Labeled>
      </Grid>
    </Grid>
  );
};
