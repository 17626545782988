import React from 'react';
import { useRecordContext } from 'react-admin';
import { PriceField } from '../components/PriceField';


export const RemainingPayment = (props) => {
  const record = useRecordContext(props);

  return (
    <PriceField
      {...props}
      source="value"
      record={{
        value: record.remainingPrice - record.bankPrice,
      }} />
  );
};
