import { useCallback } from 'react';
import { useNotify } from 'react-admin';

import { httpClient } from './httpClient';

export const useUpdate = (url) => {
  const notify = useNotify();

  return useCallback((body) => {
    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(() => notify('app.settings.information_saved', { type: 'success' }))
      .catch((error) => {
        console.error(error);
        notify('app.settings.failed_to_update_data', { type: 'error' });
      });
  }, [url, notify]);
};
