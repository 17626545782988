import React from 'react';
import { Avatar, Typography, Tooltip } from '@mui/material';

export const ProviderAvatar = ({ provider }) => {
  return (
    <Tooltip title={provider.name} placement="bottom">
      <Avatar>
        <Typography
          variant="overline"
          style={{
            background: provider.color,
            padding: 24,
          }}
        >
          {provider.shortName}
        </Typography>
      </Avatar>
    </Tooltip>
  );
};
