import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslate, Title, useNotify } from 'react-admin';

import { useParking } from '../useParking';
import { Loadable } from '../components/Loadable';
import { ParkingSettings } from './ParkingSettings';

const useStyles = makeStyles({
  root: {
    marginTop: '16px',
  },
});

export const SettingsForm = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const { parking, isLoading, update } = useParking();

  const handleUpdateSettings = (settings) => {
    update(settings)
      .then(() => notify('app.settings.information_saved', { type: 'success' }))
      .catch((error) => {
        console.error(error);
        notify('app.settings.failed_to_update_data', { type: 'error' });
      });
  };

  return (
    <div className={classes.root}>
      <Title title={translate('app.settings.title')} />
      <Loadable isLoading={isLoading}>
        <ParkingSettings parking={parking} update={handleUpdateSettings} />
      </Loadable>
    </div>
  );
};
