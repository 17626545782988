import React, { useMemo } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useShowContext, useTranslate } from 'react-admin';
import { useParking } from '../../useParking';
import { useSafeGetOne } from '../CreateReservation/useSafeGetOne';

export const Ticket = () => {
  const { record: reservation } = useShowContext();
  const translate = useTranslate();

  return (
    <div>
      <TicketTitle reservation={reservation} />
      <Box>
        <Typography
          variant='body2'>{translate('app.reservation.ticket.reservation_number') + reservation?.id}</Typography>
      </Box>
      <ProviderId reservation={reservation} />
      <Separator />
      <TicketPriceLine label={reservation?.parkDays + ' ' + translate('app.reservation.ticket.reservation_days')}
                       amount={reservation?.parkPrice} />
      {reservation?.options?.map((option, index) =>
        <TicketPriceLine key={index} label={option.label} amount={option.price} />,
      )}
      <Separator />
      <TicketPriceLine label={translate('app.reservation.ticket.total_price')} amount={reservation?.totalPrice} />
      <TicketPriceLine label={translate('app.reservation.ticket.user_reduction')}
                       amount={reservation?.bonusPrice - reservation?.totalPrice} />
      <TicketPromoCodeLine reservation={reservation} />
      <Separator />
      <TicketPriceLine label={translate('app.reservation.ticket.remaining_price')}
                       amount={reservation?.remainingPrice} />
      <TicketPriceLine label={translate('app.reservation.ticket.bank_price')} amount={reservation?.bankPrice} />
      <Separator />
      <TicketPriceLine label={translate('app.reservation.ticket.to_paid')}
                       amount={reservation?.remainingPrice - reservation?.bankPrice} bold />
    </div>
  );
};

const TicketTitle = ({ reservation }) => {
  const translate = useTranslate();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
      <Box>
        <Typography variant='h6'>{translate('app.reservation.ticket.title')}</Typography>
      </Box>
      <Box>
        <Typography
          variant='body2'>{translate(reservation?.checked === true ? 'app.reservation.ticket.verified' : 'app.reservation.ticket.not_verified')}</Typography>
      </Box>
    </Box>
  );
};

const ProviderId = ({ reservation }) => {
  const { parking } = useParking();
  const provider = useMemo(
    () => parking?.getReservationProviderById(reservation.providerId),
    [parking, reservation],
  );
  const translate = useTranslate();

  return (
    <Typography variant='body2'>{translate('app.reservation.ticket.seller') + ' : ' + provider.name}</Typography>
  );
};

const TicketPromoCodeLine = ({ reservation }) => {
  const { data } = useSafeGetOne(
    'promoCodes',
    { id: reservation?.promoCodeId },
  );
  const translate = useTranslate();

  return (
    <TicketPriceLine label={translate('app.reservation.ticket.promo_code_reduction')}
                     amount={(data?.amount || 0) * -1} />
  );
};

const TicketPriceLine = ({ label, amount, bold }) => {
  const style = bold && {
    fontWeight: 'bold',
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <Box>
        <Typography variant='body2' sx={style}>{label}</Typography>
      </Box>
      <Box>
        <Typography variant='body2' sx={style}>{(amount / 100).toFixed(2) + ' €'}</Typography>
      </Box>
    </Box>
  );
};

const Separator = () => {
  return (
    <>
      <Box mt='1em' />
      <Divider />
      <Box mt='1em' />
    </>
  );
};