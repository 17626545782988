import React, { useMemo } from 'react';
import { TextField, useRecordContext } from 'react-admin';

import { useParking } from '../useParking';
import { Loadable } from './Loadable';

export const ProviderIdField = (props) => {
  const reservation = useRecordContext();
  const { parking, isLoading } = useParking();
  const provider = useMemo(
    () => parking?.getReservationProviderById(reservation.providerId),
    [parking, reservation]
  );

  return (
    <Loadable isLoading={isLoading}>
      <TextField {...props} record={{ value: provider.name }} source="value" />
    </Loadable>
  );
};
