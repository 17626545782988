import React, { useMemo, useState } from 'react';
import { Box, Card } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListIcon from '@mui/icons-material/List';
import {
  BooleanField,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  ListBase,
  TextField,
  Title,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useNavigate } from 'react-router-dom';

import { useParking } from '../../useParking';
import { AsideProviderFilter } from '../../providers/AsideProviderFilter';

export const ListSeason = () => {
  const [showAsCalendar, setShowAsCalendar] = useState(false);

  return (
    <ListBase disableSyncWithLocation perPage={1000}>
      <Title title={'app.season.title'} />
      <Box>
        <TopToolbar sx={{ minHeight: { sm: 56 } }}>
          <Button
            label={showAsCalendar ? 'app.season.list' : 'app.season.calendar'}
            startIcon={showAsCalendar ? <ListIcon /> : <CalendarMonthIcon />}
            onClick={() => setShowAsCalendar(!showAsCalendar)}
          />
          <CreateButton />
        </TopToolbar>
        <Box display='flex'>
          <AsideProviderFilter />
          <Box flex={1}>
            <Card>{showAsCalendar ? <CalendarView /> : <ListView />}</Card>
          </Box>
        </Box>
      </Box>
    </ListBase>
  );
};

const ListView = () => (
  <>
    <Datagrid bulkActionButtons={false} rowClick={(id) => `/seasons/${id}/show`}>
      <TextField label='app.season.name' source='name' />
      <DateField label='app.season.createAt' source='createAt' />
      <DateField label='app.season.beginAt' source='beginAt' />
      <DateField label='app.season.endAt' source='endAt' />
      <BooleanField label='app.season.used' source='used' sortable={false} />
      <BooleanField label='app.season.isDefault' source='isDefault' />
    </Datagrid>
  </>
);

function formatSeason(provider, season, translate) {
  return {
    id: season.id,
    title: `${provider?.name || translate('app.season.user_season')}: ${season.name}`,
    start: new Date(season.beginAt),
    end: new Date(season.endAt),
    color: provider?.color,
  };
}

const useSeasonEvents = (props) => {
  const translate = useTranslate();
  const { parking } = useParking();
  const providerMap = useMemo(() => new Map(parking.reservationProviders.map((obj) => [obj.id, obj])), [parking]);
  const { data } = useListContext(props);

  return useMemo(
    () => data.map((season) => formatSeason(providerMap.get(season.providerId), season, translate)),
    [providerMap, data],
  );
};

const CalendarView = (props) => {
  const navigate = useNavigate();
  const events = useSeasonEvents(props);
  const onEventClicked = (info) => navigate(`/seasons/${info.event.id}/show`);

  return (
    <Box sx={{ padding: 2 }}>
      <FullCalendar
        initialView='dayGridMonth'
        editable={false}
        locale='fr'
        displayEventTime={false}
        plugins={[dayGridPlugin]}
        events={events}
        eventClick={onEventClicked}
      />
    </Box>
  );
};
