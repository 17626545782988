import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useRecordContext } from 'react-admin';

export const ObservationField = ({ source, ...props }) => {
  const record = useRecordContext(props);

  return (
    <Tooltip title={record[source] || ''} placement="left">
      <span>{record[source] && record[source].length > 0 ? 'O' : ''}</span>
    </Tooltip>
  );
};
