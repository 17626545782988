import React from 'react';
import { TextField, useTranslate, useRecordContext } from 'react-admin';

/**
 * Format reservation status.
 */
export const MaxField = ({ label, source, ...props }) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  const value = record[source];
  const newRecord = {
    ...record,
  };

  if (value === -1) {
    newRecord[source] = `${record.defaultMax} (${translate('app.days.default')})`;
  }

  return <TextField label={label} source={source} record={newRecord} />;
};
