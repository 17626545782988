import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { Avatar } from '@mui/material';

import { useParking } from '../useParking';
import { Loadable } from '../components/Loadable';
import { ProviderAvatar } from '../providers/ProviderAvatar';

export const ReservationProviderAvatarField = () => {
  const reservation = useRecordContext();
  const { parking, isLoading } = useParking();
  const provider = useMemo(
    () => parking?.getReservationProviderById(reservation.providerId),
    [parking, reservation]
  );

  return (
    <Avatar>
      <Loadable isLoading={isLoading}>{provider && <ProviderAvatar provider={provider} />}</Loadable>
    </Avatar>
  );
};
