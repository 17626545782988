import React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { Route } from 'react-router-dom';

import { httpClient } from './httpClient';
import i18n from './i18n';
import Dashboard from './dashboard';
import settings from './settings';
import users from './users';
import reservations from './reservations';
import daily from './daily';
import waitings from './waitings';
import days from './days';
import promoCodes from './promoCodes';
import seasons from './seasons';
import paymentCheck from './paymentCheck';
import pendingReservations from './pendingReservations';
import layout from './layout';
import { authProvider } from './authProvider';
import { SUPER_ADMIN } from './users/adminTypes';
import { darkTheme, lightTheme } from './layout/themes';
import Password from './password';
import { Component as Providers } from './providers';

/**
 * Main component.
 */
export const App = () => (
  <Admin
    i18nProvider={i18n}
    theme={lightTheme}
    darkTheme={darkTheme}
    dataProvider={jsonServerProvider('', httpClient)}
    authProvider={authProvider}
    layout={layout}
    dashboard={Dashboard}
  >
    {(permissions) => [
      <Resource name="users" {...users} />,
      <Resource name="reservations" {...reservations} />,
      <Resource name="reservation-pictures" />,
      <Resource name="daily" {...daily} />,
      <Resource name="days" {...days} edit={permissions === SUPER_ADMIN ? days.edit : null} />,
      <Resource
        name="seasons"
        {...seasons}
        edit={permissions === SUPER_ADMIN ? seasons.edit : null}
        create={permissions === SUPER_ADMIN ? seasons.create : null}
      />,
      <Resource
        name="promoCodes"
        {...promoCodes}
        edit={permissions === SUPER_ADMIN ? promoCodes.edit : null}
        create={permissions === SUPER_ADMIN ? promoCodes.create : null}
      />,
      <Resource name="pendingReservations" {...pendingReservations} />,
      <CustomRoutes>
        <Route path="/waitings" element={<waitings.list />} />
      </CustomRoutes>,
      <CustomRoutes>
        <Route path="/paymentCheck" element={<paymentCheck.list />} />
      </CustomRoutes>,
      <CustomRoutes>
        <Route path="/settings" element={<settings.component />} />
      </CustomRoutes>,
      <CustomRoutes>
        <Route path="/password" element={<Password />} />
      </CustomRoutes>,
      <CustomRoutes>
        <Route path="/providers" element={<Providers />} />
      </CustomRoutes>,
    ]}
  </Admin>
);
