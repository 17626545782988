import React, { useMemo } from 'react';
import {
  BooleanField,
  DateField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { SeasonTitle } from '../SeasonTitle';
import { PriceField } from '../../components/PriceField';
import { OptionIconButton } from '../../reservations/options/OptionIconButton';
import { useRecordOptions } from '../seasonOptionsHooks';
import { useParking } from '../../useParking';
import { Loadable } from '../../components/Loadable';

const SeasonProviderIdField = (props) => {
  const reservation = useRecordContext();
  const translate = useTranslate();
  const { parking, isLoading } = useParking();
  const providerName = useMemo(
    () => parking?.getReservationProviderById(reservation.providerId)?.name || 'app.season.providerId.none',
    [parking, reservation],
  );

  return (
    <Loadable isLoading={isLoading}>
      <TextField {...props} record={{ value: translate(providerName) }} source='value' />
    </Loadable>
  );
};

const SeasonInformationTab = (props) => (
  <Tab {...props} label='app.season.information'>
    <SeasonProviderIdField label='app.season.providerId.title' source='providerId' />
    <TextField label='app.season.name' source='name' />
    <DateField label='app.season.beginAt' source='beginAt' />
    <DateField label='app.season.endAt' source='endAt' />
    <BooleanField label='app.season.isDefault' source='isDefault' />
  </Tab>
);

const PriceTab = (props) => (
  <Tab {...props} label='app.season.days'>
    <PriceField label='app.season.1d' source='1d' />
    <PriceField label='app.season.2d' source='2d' />
    <PriceField label='app.season.3d' source='3d' />
    <PriceField label='app.season.4d' source='4d' />
    <PriceField label='app.season.5d' source='5d' />
    <PriceField label='app.season.6d' source='6d' />
    <PriceField label='app.season.7d' source='7d' />
    <PriceField label='app.season.8d' source='8d' />
    <PriceField label='app.season.9d' source='9d' />
    <PriceField label='app.season.10d' source='10d' />
    <PriceField label='app.season.11d' source='11d' />
    <PriceField label='app.season.12d' source='12d' />
    <PriceField label='app.season.13d' source='13d' />
    <PriceField label='app.season.14d' source='14d' />
    <PriceField label='app.season.15d' source='15d' />
    <PriceField label='app.season.extraDay' source='extraDay' />
  </Tab>
);

function formatOption(option) {
  const included = option.mandatory ? 'inclus' : null;
  const defaultValue = option.defaultValue ? 'activé par défaut' : null;

  return {
    ...option,
    extraInformation: [included, defaultValue].filter((a) => !!a).join(' et '),
  };
}

const OptionsTab = (props) => {
  const options = useRecordOptions(props);
  const formattedOptions = useMemo(() => options.map(formatOption), [options]);

  return (
    <Tab {...props} label='app.season.options'>
      <List>
        {formattedOptions.map((option, index) => {
          return (
            <ListItem key={index}>
              <ListItemAvatar>
                <OptionIconButton index={index} option={option} disabled />
              </ListItemAvatar>
              <ListItemText primary={option.label} secondary={option.extraInformation} />
              <PriceField record={option} source='price' />
            </ListItem>
          );
        })}
      </List>
    </Tab>
  );
};

export const ShowSeason = (props) => (
  <Show {...props} title={<SeasonTitle />}>
    <TabbedShowLayout>
      <SeasonInformationTab />
      <PriceTab />
      <OptionsTab />
    </TabbedShowLayout>
  </Show>
);
