import React from 'react';
import {
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  EmailField,
  ExportButton,
  FilterButton,
  List,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
} from 'react-admin';

import { userExporter } from './UserExporter';
import { UserExpandPanel } from './UserExpandPanel';
import { SUPER_ADMIN } from './adminTypes';
import { FullNameField } from './FullNameField';
import { PhoneNumberField } from './PhonesNumberField';

const userFilters = [
  <TextInput label='app.user.search' source='q' alwaysOn />,
  <TextInput label='app.user.email' source='email' alwaysOn />,
  <TextInput label='app.user.firstname' source='firstname' />,
  <TextInput label='app.user.lastname' source='lastname' />,
  <TextInput label='app.user.carBrand' source='carBrand' />,
  <TextInput label='app.user.registration' source='registration' />,
  <TextInput label='app.user.mobile' source='mobile' />,
  <BooleanInput label='app.user.premium' source='premium' />,
  <DateInput label='app.user.createAt_date-gte' source='createAt_date-gte' />,
  <DateInput label='app.user.createAt_date-lte' source='createAt_date-lte' />,
];

const PostListActions = () => {
  const { permissions } = usePermissions();

  // TODO: create a isSuperAdmin property on permissions
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      {permissions === SUPER_ADMIN ? <ExportButton maxResults={10000} /> : null}
    </TopToolbar>
  );
};

export const ListUser = () => (
  <List title='app.user.users' filters={userFilters} exporter={userExporter} actions={<PostListActions />}>
    <Datagrid bulkActionButtons={false} rowClick='expand' expand={<UserExpandPanel />} expandSingle>
      <TextField source='id' />
      <DateField label='app.user.createAt' source='createAt' showTime />
      <FullNameField label='app.user.name' />
      <PhoneNumberField label='app.user.phone' source='mobile' />
      <EmailField label='app.user.email' source='email' />
      <BooleanField label='app.user.premium' source='premium' />
      <TextField label='app.user.carBrand' source='carBrand' />
      <TextField label='app.user.registration' source='registration' />
      <ShowButton />
    </Datagrid>
  </List>
);
