import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  NumberField,
  TextField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ShowButton,
  DateField,
} from 'react-admin';
import { Grid } from '@mui/material';

import { PercentageField } from '../components/PercentageField';
import { FullNameField } from '../reservations/FullNameField';
import { PriceField } from '../components/PriceField';
import { ProviderIdField } from '../components/ProviderIdField';
import { PromoCodeUsageField } from './PromoCodeUsageField';

const PromoCodeDetails = (props) => (
  <Grid container direction="row" rowSpacing={1} columnSpacing={1}>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.id">
        <NumberField source="id" />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.createAt">
        <DateField source="createAt" showTime />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.updatedAt">
        <DateField source="updatedAt" showTime />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.reservation.providerId">
        <ProviderIdField source="providerId" />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.code">
        <TextField source="code" />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.label">
        <TextField source="label" />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.start">
        <DateField source="start" />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.end">
        <DateField source="end" />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.amount">
        <PriceField source="amount" />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.percentage">
        <PercentageField source="percentage" />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.usage">
        <PromoCodeUsageField />
      </Labeled>
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <Labeled label="app.promoCodes.limitedUserId">
        <ReferenceField
          source="limitedUserId"
          reference="users"
          link="show"
          basePath={props.basePath}
        >
          <FullNameField label="app.user.name" source="Name" />
        </ReferenceField>
      </Labeled>
    </Grid>
  </Grid>
);

export const ShowPromoCode = (props) => (
  <Show title="app.promoCodes.title">
    <TabbedShowLayout>
      <Tab label="app.promoCodes.information">
        <PromoCodeDetails basePath={props.basePath} />
      </Tab>
      <Tab label="app.promoCodes.reservations" path="reservations">
        <ReferenceManyField
          reference="reservations"
          target="promoCodeId"
          label=""
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
          perPage={10}
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <DateField label="app.reservation.createAt" source="createAt" showTime />
            <ReferenceField label="app.user.user" source="userId" reference="users">
              <FullNameField label="app.user.name" source="Name" />
            </ReferenceField>
            <DateField label="app.reservation.deposit" source="deposit" showTime />
            <DateField label="app.reservation.pickup" source="pickup" showTime />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
