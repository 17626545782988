import React, { useMemo, useCallback } from 'react';
import { Button, useListContext } from 'react-admin';
import GetApp from '@mui/icons-material/GetApp';
import moment from 'moment';

import { download } from '../helpers/fileDownloader';

export const BulkDownloadInvoice = (props) => {
  const { selectedIds, filterValues } = useListContext(props);
  const ids = useMemo(() => selectedIds.map((id) => 'id=' + id).join('&'), [selectedIds]);
  const date = useMemo(() => moment(filterValues.date).format('YYYY-MM-D'), [filterValues]);

  const handleClick = useCallback(() => {
    download({
      url: `${process.env.REACT_APP_API_URL}/admin/reservations/invoice?pdftype=invoice&date=${date}&${ids}`,
      dataType: 'data:application/pdf;charset=utf8',
    });
  }, [date, ids]);

  return (
    <Button onClick={handleClick} label="Factures">
      <GetApp />
    </Button>
  );
};
