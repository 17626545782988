import React from 'react';
import { Avatar, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, OutlinedInput } from '@mui/material';
import { Button } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { OptionIconButton } from '../options/OptionIconButton';
import { useFormReservationOptions } from './useFormReservationOptions';
import { AddOptionButton } from './AddOptionButton';

export const EditReservationOptionsTab = () => {
  const { options, setOptions } = useFormReservationOptions();

  const handlePriceChange = (optionIndex) => (event) => {
    const value = event.target.value;
    const price = parseInt(value);

    options[optionIndex].price = price * 100;
    setOptions(options);
  };

  const handleDeleteOption = (optionIndex) => () => {
    options.splice(optionIndex, 1);
    setOptions(options);
  };

  return (
    <List>
      {options.map((option, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <OptionIconButton index={index} option={option} disabled />
          </ListItemAvatar>
          <ListItemText primary={option.label} secondary={option.description} />
          <OutlinedInput
            sx={{ marginLeft: 16 }}
            type='number'
            value={option.price / 100}
            endAdornment={<InputAdornment position='start'>€</InputAdornment>}
            onChange={handlePriceChange(index)}
          />
          <Button
            label='Supprimer'
            startIcon={<DeleteIcon />}
            color='error'
            sx={{ marginLeft: 2 }}
            onClick={handleDeleteOption(index)}
          />
        </ListItem>
      ))}
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AddIcon />
          </Avatar>
        </ListItemAvatar>
        <AddOptionButton />
      </ListItem>
    </List>
  );
};
