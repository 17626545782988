import React, { useMemo, useCallback } from 'react';
import { Button, useListContext } from 'react-admin';
import Label from '@mui/icons-material/Label';
import moment from 'moment';

export const BulkExportLabel = (props) => {
  const { selectedIds, filterValues } = useListContext(props);
  const ids = useMemo(() => selectedIds.map((id) => 'id=' + id).join('&'), [selectedIds]);
  const date = useMemo(() => moment(filterValues.date).format('YYYY-MM-D'), [filterValues]);

  const handleClick = useCallback(() => {
    window.open(`${process.env.REACT_APP_API_URL}/admin/label?date=${date}&${ids}`, '_blank');
  }, [date, ids]);

  return (
    <Button onClick={handleClick} label="Etiquettes">
      <Label />
    </Button>
  );
};
