import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import { Grid } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Title, useNotify, useTranslate } from 'react-admin';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { PasswordField } from './PasswordField';
import { httpClient } from '../httpClient';
import { isNullOrEmpty } from '../helpers/StringUtils';

const useStyles = makeStyles({
  root: {
    marginTop: '16px',
  },
});

const Configuration = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();

  const classes = useStyles();
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [state, setState] = useState({
    oldPassword: '',
    password: '',
    confirmationPassword: '',
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  useEffect(() => {
    if (isNullOrEmpty(state.password) || isNullOrEmpty(state.confirmationPassword)) {
      // Some field are still empty
      setConfirmPasswordError(false);
      setCanSubmit(false);
    } else {
      setConfirmPasswordError(state.password !== state.confirmationPassword);
      setCanSubmit(state.password === state.confirmationPassword && !isNullOrEmpty(state.oldPassword));
    }
  }, [state.password, state.oldPassword, state.confirmationPassword]);

  const submit = () => {
    return httpClient('/auth/password', {
      method: 'PUT',
      body: JSON.stringify({ oldPassword: state.oldPassword, newPassword: state.password }),
    })
      .then(() => {
        notify('app.password.password_changed', { type: 'success' });
        navigate('/');
      })
      .catch((err) => {
        if (err.status === 400) {
          setOldPasswordError(true);
        } else {
          notify('app.dashboard.network_error', { type: 'error' });
        }
      });
  };

  return (
    <Card className={classes.root}>
      <Title title={translate('app.app_bar.change_password')} />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Modifier mon mot de passe
        </Typography>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <PasswordField
              id="outlined-adornment-old-password"
              label="Ancien mot de passe"
              value={state.oldPassword}
              onChange={handleChange('oldPassword')}
              aria-describedby="confirm-password-error-text"
              error={oldPasswordError ? 'Le mot de passe ne correspond pas' : null}
            />
          </Grid>
          <Grid item sm xs={12}>
            <PasswordField
              id="outlined-adornment-new-password"
              label="Nouveau mot de passe"
              value={state.password || ''}
              onChange={handleChange('password')}
            />
          </Grid>
          <Grid item sm xs={12}>
            <PasswordField
              id="outlined-adornment-confirm-password"
              label="Confirmer mon nouveau mot de passe"
              value={state.confirmationPassword}
              onChange={handleChange('confirmationPassword')}
              aria-describedby="confirm-password-error-text"
              error={confirmPasswordError ? 'Les mots de passe ne correspondent pas' : null}
            />
          </Grid>
          <Grid item xs={12}>
            <Button size="large" variant="contained" color="primary" disabled={!canSubmit} onClick={submit}>
              Modifier
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Configuration;
