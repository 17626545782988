import React from 'react';
import { useRecordContext } from 'react-admin';
import { OptionIconButton } from '../reservations/options/OptionIconButton';
import { Grid } from '@mui/material';

export const OptionsField = (props) => {
  const { options } = useRecordContext(props);
  const optionComponents = options.map((option, index) => (
    <OptionIconButton key={index} index={index} option={option} />
  ));

  return (
    <Grid container
          spacing={1}
          sx={{ minWidth: 100 }}
          justifyContent='center'
          alignItems='center'>
      {optionComponents.map((component, index) => (
        <Grid item key={index}>
          {component}
        </Grid>
      ))}
    </Grid>
  );
};
