import { Euro } from '@mui/icons-material';

import { PaymentCheckList } from './PaymentCheckList';

const result = {
  icon: Euro,
  list: PaymentCheckList,
}

export default result;
