import * as React from 'react';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { useListFilterContext, shallowEqual } from 'ra-core';
import matches from 'lodash/matches';
import pickBy from 'lodash/pickBy';

import { ProviderAvatar } from '../providers/ProviderAvatar';

const arePropsEqual = (prevProps, nextProps) =>
  prevProps.provider === nextProps.provider && shallowEqual(prevProps.value, nextProps.value);

export const ProviderFilterListItem = memo((props) => {
  const { provider, value, ...rest } = props;
  const { filterValues, setFilters } = useListFilterContext();

  const isSelected = matches(pickBy(value, (val) => typeof val !== 'undefined'))(filterValues);

  const addFilter = () => {
    setFilters({ ...filterValues, ...value }, null, false);
  };

  const removeFilter = () => {
    const keysToRemove = Object.keys(value);
    const filters = Object.keys(filterValues).reduce(
      (acc, key) => (keysToRemove.includes(key) ? acc : { ...acc, [key]: filterValues[key] }),
      {}
    );

    setFilters(filters, null, false);
  };

  const toggleFilter = () => (isSelected ? removeFilter() : addFilter());

  return (
    <StyledListItem onClick={toggleFilter} selected={isSelected} disablePadding {...rest}>
      <ListItemButton disableGutters className={FilterListItemClasses.listItemButton}>
        <ListItemAvatar>
          <ProviderAvatar provider={provider} />
        </ListItemAvatar>
        <ListItemText
          primary={provider.name}
          className={FilterListItemClasses.listItemText}
          data-selected={isSelected ? 'true' : 'false'}
        />
        {isSelected && (
          <ListItemSecondaryAction
            onClick={(event) => {
              event.stopPropagation();
              toggleFilter();
            }}
          >
            <IconButton size="small">
              <CancelIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItemButton>
    </StyledListItem>
  );
}, arePropsEqual);

const PREFIX = 'RaFilterListItem';

export const FilterListItemClasses = {
  listItemButton: `${PREFIX}-listItemButton`,
  listItemText: `${PREFIX}-listItemText`,
};

const StyledListItem = styled(ListItem, {
  name: PREFIX,
  overridesResolver: (_, styles) => styles.root,
})(() => ({
  [`& .${FilterListItemClasses.listItemButton}`]: {
    paddingRight: '2em',
    paddingLeft: '0.5em',
  },
  [`& .${FilterListItemClasses.listItemText}`]: {
    margin: 0,
  },
}));
