import React from 'react';
import { isReportReservation, ReportReservation } from './ReportReservation';
import { Insurance, isInsurance } from './Insurance';
import { Cleaning, isCleaning } from './Cleaning';
import { ElectricCharging, isElectricCharging } from './ElectricCharging';
import { CarCondition, isCarCondition } from './CarCondition';
import { UnknownOption } from './UnknownOption';
import { useNotify, useRecordContext, useRefresh, useUpdate } from 'react-admin';

function useActivateOption(index) {
  const recordContext = useRecordContext();
  // TODO: realId should not be handled here
  const id = recordContext?.realId || recordContext?.id;
  const options = recordContext?.options || [];
  const notify = useNotify();
  const refresh = useRefresh();
  const activateParam = {
    id,
    data: {
      ...recordContext,
      options: options.map((option, i) => (i === index ? { ...option, active: true } : option)),
    },
    previousData: recordContext,
  };
  const deactivateParam = {
    id,
    data: {
      ...recordContext,
      options: options.map((option, i) => (i === index ? { ...option, active: false } : option)),
    },
    previousData: recordContext,
  };

  const responseHandler = {
    onSuccess: () => {
      refresh();
      notify('app.reservation.option_update_success', { type: 'success' });
    },
    onFailure: () => {
      notify('app.reservation.option_update_failed', { type: 'error' });
    },
  };

  const [activate, { isLoading: isActivateLoading }] = useUpdate('reservations', activateParam, responseHandler);
  const [deactivate, { isLoading: isDeactivateLoading }] = useUpdate('reservations', deactivateParam, responseHandler);

  return { activate, deactivate, isLoading: isActivateLoading || isDeactivateLoading };
}

export const OptionIconButton = ({ index, option, disabled }) => {
  const { activate, deactivate, isLoading } = useActivateOption(index);

  const handleClick = (e) => {
    // useful to prevent click bubbling in a datagrid with rowClick
    e.stopPropagation();

    if (option.active) {
      deactivate();
    } else {
      activate();
    }
  };

  if (isReportReservation(option)) {
    return <ReportReservation option={option} onClick={handleClick} disabled={disabled} isLoading={isLoading} />;
  }
  if (isInsurance(option)) {
    return <Insurance option={option} onClick={handleClick} disabled={disabled} isLoading={isLoading} />;
  }
  if (isCleaning(option)) {
    return <Cleaning option={option} onClick={handleClick} disabled={disabled} isLoading={isLoading} />;
  }
  if (isElectricCharging(option)) {
    return <ElectricCharging option={option} onClick={handleClick} disabled={disabled} isLoading={isLoading} />;
  }
  if (isCarCondition(option)) {
    return <CarCondition option={option} onClick={handleClick} disabled={disabled} isLoading={isLoading} />;
  }

  return <UnknownOption option={option} onClick={handleClick} disabled={disabled} isLoading={isLoading} />;
};
