import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DeleteButton,
  DeleteWithConfirmButton,
  Edit,
  email,
  Form,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  TextInput,
  Toolbar,
  usePermissions,
} from 'react-admin';
import { Box, Divider, Grid, Typography } from '@mui/material';

import { UserTitle } from './UserTitle';
import { UserTypeInput } from './UserTypeInput';
import { SUPER_ADMIN } from './adminTypes';
import { SaveButtonWithConfirmation } from '../components/SaveButtonWithConfirmation';
import { UserRenderer } from '../reservations/CreateReservation';

const priceListRenderer = (choice) => (choice ? `${choice.name || ''} ${choice.id ? '(' + choice.id + ')' : ''}` : '');

const PostEditToolbar = () => {
  const { permissions } = usePermissions();

  return (
    <Toolbar style={{ justifyContent: 'space-between' }}>
      <SaveButton redirect='show' />
      {permissions === SUPER_ADMIN && <DeleteWithConfirmButton />}
    </Toolbar>
  );
};

export const EditUser = () => {
  const { permissions } = usePermissions();

  return (
    <Edit title={<UserTitle />} mutationMode='pessimistic' redirect='show'>
      <Form toolbar={<PostEditToolbar />}>
        <Grid container columnSpacing={4} style={{ marginTop: 16, paddingLeft: 16, paddingRight: 16 }}>
          <Grid item xs={12} sm={8}>
            <Typography variant='h6' gutterBottom>
              Information client
            </Typography>
            <Grid item container direction='row' columnSpacing={2} rowSpacing={1}>
              <Grid item sm={6}>
                <TextInput label='app.user.firstname' source='firstname' fullWidth validate={required()} />
              </Grid>
              <Grid item sm={6}>
                <TextInput label='app.user.lastname' source='lastname' fullWidth validate={required()} />
              </Grid>
              <Grid item sm={6}>
                <TextInput label='app.user.email' source='email' validate={[email(), required()]} fullWidth />
              </Grid>
              <Grid item sm={6}>
                <TextInput label='app.user.mobile' source='mobile' fullWidth validate={required()} />
              </Grid>
              <Grid item sm={6}>
                <TextInput label='app.user.carBrand' source='carBrand' fullWidth />
              </Grid>
              <Grid item sm={6}>
                <TextInput label='app.user.registration' source='registration' fullWidth />
              </Grid>
              <Grid item sm={6}>
                <TextInput label='app.user.address' source='address' fullWidth />
              </Grid>
              <Grid item sm={3}>
                <TextInput label='app.user.zipcode' source='zipcode' fullWidth />
              </Grid>
              <Grid item sm={3}>
                <TextInput label='app.user.city' source='city' fullWidth />
              </Grid>
            </Grid>

            <Divider style={{ width: '100%', marginTop: 8, marginBottom: 16 }} />

            <Typography variant='h6' gutterBottom>
              Information professionelles
            </Typography>

            <Grid item container direction='row' columnSpacing={2} rowSpacing={1}>
              <Grid item sm={6}>
                <TextInput label='app.user.clientName' source='clientName' fullWidth />
              </Grid>
              <Grid item sm={6}>
                <TextInput label='app.user.clientService' source='clientService' fullWidth />
              </Grid>
              <Grid item sm={6}>
                <TextInput label='app.user.clientAddress' source='clientAddress' fullWidth />
              </Grid>
              <Grid item sm={3}>
                <TextInput label='app.user.clientZipcode' source='clientZipcode' fullWidth />
              </Grid>
              <Grid item sm={3}>
                <TextInput label='app.user.clientCity' source='clientCity' fullWidth />
              </Grid>
            </Grid>

            <Grid item container direction='row' columnSpacing={2} rowSpacing={1}>
              <Grid item sm={6}>
                <ReferenceInput label='app.user.supervisorId' source='supervisorId' reference='users' fullWidth>
                  <AutocompleteInput optionText={UserRenderer} />
                </ReferenceInput>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid item container direction='column' columnSpacing={2} rowSpacing={1}>
              <Typography variant='h6' gutterBottom>
                Information technique
              </Typography>
              <TextInput readOnly label='Id' source='id' fullWidth />
              <BooleanInput label='app.user.premium' source='premium' fullWidth />
              <UserTypeInput
                label='app.user.type'
                source='type'
                readOnly={permissions !== SUPER_ADMIN}
                fullWidth
                validate={required()}
              />
              <NumberInput label='app.user.bonus' source='bonus' readOnly={permissions !== SUPER_ADMIN} fullWidth />
              <ReferenceInput
                label='app.user.priceList'
                source='priceList'
                reference='seasons'
                readOnly={permissions !== SUPER_ADMIN}
                fullWidth
              >
                <AutocompleteInput optionText={priceListRenderer} />
              </ReferenceInput>
              <TextInput label='app.user.comment' source='comment' fullWidth multiline />
            </Grid>
          </Grid>
        </Grid>

        <Toolbar>
          <Box display='flex' justifyContent='space-between' width='100%'>
            <SaveButtonWithConfirmation mutationMode='pessimistic' />
            {permissions === SUPER_ADMIN && <DeleteButton />}
          </Box>
        </Toolbar>
      </Form>
    </Edit>
  );
};
