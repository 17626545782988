import React from 'react';
import { Datagrid, ReferenceManyField, Pagination, ShowButton, DateField, TextField } from 'react-admin';

import { FullNameField } from './FullNameField';
import { ReservationProviderAvatarField } from '../../reservations/ReservationProviderAvatarField';
import { OptionsField } from '../../daily/OptionsField';

export const UserReservations = () => (
  <ReferenceManyField
    reference="reservations"
    target="userId"
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    perPage={10}
    label=""
  >
    <Datagrid bulkActionButtons={false}>
      <ReservationProviderAvatarField label="app.reservation.providerId" />
      <TextField label="app.reservation.id" source="id" sortable={false} />
      <DateField label="app.reservation.createAt" source="createAt" showTime />
      <FullNameField label="app.user.name" />
      <DateField label="app.reservation.deposit" source="deposit" showTime />
      <DateField label="app.reservation.pickup" source="pickup" showTime />
      <OptionsField label="app.reservation.options" />
      <ShowButton />
    </Datagrid>
  </ReferenceManyField>
);
