import { httpClient } from './httpClient';

function login({ username, password }) {
  return httpClient('/auth/login', {
    method: 'POST',
    body: JSON.stringify({ username, password }),
  }).then((response) => {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
  });
}

function checkError(error) {
  const status = error.status;

  if (status === 401) {
    document.cookie += '=;Max-Age=-99999999;';
    return Promise.reject({ redirectTo: '/login', logoutUser: true });
  }

  return Promise.resolve();
}

function checkAuth() {
  return document.cookie && document.cookie.length > 0 ? Promise.resolve() : Promise.reject();
}

function logout() {
  return httpClient('/auth/logout', {
    method: 'POST',
  }).then(() => '/login');
}

function getIdentity() {
  return httpClient('/auth/identity').then((response) => {
    const body = response.json;

    return {
      ...body,
      fullName: `${body.firstname} ${body.lastname}`,
    };
  });
}

function getPermissions() {
  return getIdentity().then((identity) => identity.permissions);
}

export const authProvider = {
  login,
  checkError,
  checkAuth,
  logout,
  getIdentity,
  getPermissions,
};
