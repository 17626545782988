import React from 'react';
import { Grid } from '@mui/material';

import { SiteProviderCard } from './SiteProviderCard';
import { ParkcloudProviderCard } from './ParkcloudProviderCard';
import { ProviderGraph } from './ProviderGraph';

// TODO: add Parkos + add platform logos
function createProviderTypeCard(provider) {
  switch (provider.type) {
    case 'SITE':
      return <SiteProviderCard provider={provider} />;
    case 'PARKCLOUD':
      return <ParkcloudProviderCard />;
    default:
      return null;
  }
}

export const ProviderInformation = ({ provider }) => {
  const providerTypeCard = createProviderTypeCard(provider);


  return (
    <Grid container spacing={2} direction='column'>
      {providerTypeCard && (
        <Grid item>
          {providerTypeCard}
        </Grid>
      )}
      <Grid item>
        <ProviderGraph provider={provider} />
      </Grid>
    </Grid>
  );
};
