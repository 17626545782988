import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import 'datejs';

import { httpClient } from './httpClient';
import { useUpdate } from './useUpdate';

const dateTimeToTime = (value) => Date.parse(value).toString('HH:mm');

const timeToDateTime = (value) => Date.parse(value);

const parseParkingConfiguration = (data) => ({
  ...data,
  dailyMaxSpace: data.dailyMaxSpace,
  dailyPremiumExtraSpace: data.dailyPremiumExtraSpace,
  reservationMaxDuration: data.reservationMaxDuration,
  firstReservationTime: dateTimeToTime(data.firstReservationTime),
  endReservationTime: dateTimeToTime(data.endReservationTime),
});

const formatParkingConfiguration = (config) => ({
  ...config,
  firstReservationTime: timeToDateTime(config.firstReservationTime),
  endReservationTime: timeToDateTime(config.endReservationTime),
});

const createProviderMap = (providers) => {
  if (providers) {
    return new Map(providers.map((object) => [object.id, object]));
  } else {
    return {};
  }
};

export const useParking = () => {
  const { refetch, isLoading, data, error } = useQuery(
    'parking',
    () =>
      httpClient('/parking')
        .then((response) => response.json)
        .then(parseParkingConfiguration),
    {
      cacheTime: 5 * 60 * 1000, // 5 minutes
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  );

  const providerMap = useMemo(() => createProviderMap(data?.reservationProviders), [data]);

  const getReservationProviderById = useCallback((providerId) => providerMap.get(providerId), [providerMap]);

  const updateParking = useUpdate('/parking');

  const update = useCallback(
    (data) => updateParking(formatParkingConfiguration(data)).then(() => refetch({ updateCache: true })),
    [updateParking, refetch],
  );

  return useMemo(() => {
    return {
      parking: data ? { ...data, getReservationProviderById } : null,
      isLoading: isLoading,
      error: error,
      update,
    };
  }, [isLoading, data, error, getReservationProviderById, update]);
};
