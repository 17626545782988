import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { httpClient } from '../httpClient';

export const useProviderRevenue = (provideId) => {
  const result = useQuery(['providerRevenue', provideId], () =>
    httpClient('/provider/revenue/' + provideId)
      .then((response) => response.json)
  );

  return useMemo(() => {
    return {
      revenuePerDay: result.data,
      isLoading: result.isLoading,
      error: result.error,
    };
  }, [result]);
};
