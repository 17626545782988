import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { downloadCSV } from 'react-admin';

/**
 * Replace export ',' delimiter with ';'.
 */
export const exporter = (data) => {
    const csv = convertToCSV({
        data,
    }, {
        delimiter: ';',
    });

    downloadCSV(csv, 'users');
};
