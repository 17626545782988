import { Today } from '@mui/icons-material';

import { DailyList } from './DailyList';

const result = {
  icon: Today,
  list: DailyList,
};

export default result;
