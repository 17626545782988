import React from 'react';
import { MenuItemLink } from 'react-admin';

export const ExternalMenuItemLink = ({ to, ...props }) => {
  function handleClick() {
    window.open(to, '_blank');
  }

  return <MenuItemLink to="#" onClick={handleClick} {...props} />;
};
