import React, { Fragment, useMemo } from 'react';
import 'datejs';
import {
  BulkExportButton,
  BulkUpdateButton,
  Datagrid,
  ExportButton,
  FilterButton,
  List,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin';
import { useTheme } from '@mui/styles';

import { FullNameField } from '../reservations/FullNameField';
import { ReservationUserLink } from '../reservations/ReservationUserField';
import { StatusField } from '../reservations/StatusField';
import { StatusInput } from '../reservations/StatusInput';
import { FlyTrackerField } from './FlyTrackerField';
import { dailyExporter } from './dailyExporter';
import { DailyExpandPanel } from './DailyExpandPanel';
import { BulkDownloadInvoice } from './BulkDownloadInvoice';
import { BulkDownloadVoucher } from './BulkDownloadVoucher';
import { BulkExportLabel } from './BulkExportLabel';
import { RemainingPayment } from '../reservations/RemainingPayment';
import { PriceField } from '../components/PriceField';
import { FlyField } from '../reservations/FlyField';
import { StockField } from '../reservations/StockField';
import { TypeField } from '../reservations/TypeField';
import { TimeField } from '../reservations/TimeField';
import { CUSTOMER } from '../users/userTypes';
import { RECEIVED, RETURNED, WAITING_FOR_DEPOSIT } from '../reservations/status';
import { ObservationField } from './ObservationField';
import { OptionsField } from './OptionsField';
import { LazyScrollPagination } from './LazyScrollPagination';
import { DailyReservationTypeInput } from './DailyReservationTypeInput';
import { BulkExpandButton } from '../components/BulkExpandButton';
import { ReservationProviderAvatarField } from '../reservations/ReservationProviderAvatarField';

const Title = () => {
  const translate = useTranslate();
  const { filterValues } = useListContext();
  const date = useMemo(() => new Date(filterValues.date).toString('dd/MM/yyyy'), [filterValues]);

  return (
    <b>
      {translate('app.daily.title')} du {date}
    </b>
  );
};

const filters = [
  <NumberInput label='app.reservation.id' source='id' alwaysOn />,
  <DailyReservationTypeInput label='app.reservation.type.title' source='type' alwaysOn />,
  <TextInput label='app.reservation.registration' source='registration' alwaysOn />,
  <TextInput label='app.user.lastname' source='lastname' alwaysOn />,
  <TextInput label='app.reservation.carBrand' source='carBrand' />,
  <TextInput label='app.reservation.stock' source='stock' />,
  <ReferenceInput label='app.user.user' source='userId' reference='users'>
    <SelectInput optionText={<FullNameField source='Name' />} />
  </ReferenceInput>,
  <StatusInput label='app.reservation.status.title' source='status' />,
  <TextInput label='app.reservation.transactionComment' source='transactionComment' />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

const PostBulkActionButtons = (props) => {
  const { selectedIds } = useListContext(props);
  const ids = useMemo(() => selectedIds.map((item) => item.substr(0, item.indexOf('-'))), [selectedIds]);

  return (
    <Fragment>
      <BulkExpandButton />
      <BulkUpdateButton
        label='app.reservation.status.waitingForDeposit'
        data={{ status: WAITING_FOR_DEPOSIT }}
        mutationMode='pessimistic'
        selectedIds={ids}
        resource='reservations'
      />
      <BulkUpdateButton
        label='app.reservation.status.receive'
        data={{ status: RECEIVED }}
        mutationMode='pessimistic'
        selectedIds={ids}
        resource='reservations'
      />
      <BulkUpdateButton
        label='app.reservation.status.returned'
        data={{ status: RETURNED }}
        mutationMode='pessimistic'
        selectedIds={ids}
        resource='reservations'
      />
      <BulkExportLabel resource='reservations' selectedIds={ids} />
      <BulkDownloadInvoice resource='reservations' selectedIds={ids} />
      <BulkDownloadVoucher resource='reservations' selectedIds={ids} />
      <BulkExportButton resource='reservations' selectedIds={ids} />
    </Fragment>
  );
};

const reservationRowStyle = (theme) => (record, _index) => {
  const paymentRequired = record.user_type === CUSTOMER && record.remainingPrice - record.bankPrice > 0;
  const backgroundColor = theme.palette.mode === 'light' ? '#ffeaa7' : '#cd6133';
  const hoverColor = theme.palette.mode === 'light' ? '#E5D296' : '#A44D28';

  return {
    backgroundColor: paymentRequired ? backgroundColor : 'transparent',
    '&:hover': {
      backgroundColor: paymentRequired ? hoverColor : theme.palette.action.selected,
    }
  };
};

export const DailyList = () => {
  const theme = useTheme();

  return (
    <List
      title={<Title />}
      sort={{ field: 'date', order: 'ASC' }}
      filters={filters}
      exporter={dailyExporter}
      actions={<ListActions />}
      pagination={
        <LazyScrollPagination
          batchSize={50}
          loadMoreLabel='app.daily.load_more'
          noMoreLabel='app.daily.no_more_to_load'
        />
      }
      perPage={50}
    >
      <Datagrid
        rowSx={reservationRowStyle(theme)}
        rowClick='expand'
        expand={<DailyExpandPanel />}
        bulkActionButtons={<PostBulkActionButtons />}
        hover={false}
      >
        <ReservationProviderAvatarField label='app.reservation.providerId' />
        <TextField label='app.reservation.id' source='realId' />
        <TypeField label='app.daily.type' source='type' />
        <StockField label='app.reservation.stock' source='stock' />
        <TimeField label='app.daily.time' source='date' />
        <StatusField label='app.reservation.status.title' source='status' />
        <TextField label='app.reservation.client' source='lastname' />
        <TextField label='app.reservation.carBrand' source='carBrand' />
        <TextField label='app.reservation.registration' source='registration' />
        <FlyField label='app.reservation.flyNumber' source='fly' />
        <FlyTrackerField label='app.reservation.flyNumber' source='fly' />
        <ReservationUserLink label='app.user.user' source='userId' />
        <OptionsField label='app.reservation.options' />
        <PriceField label='app.reservation.total' source='bonusPrice' />
        <PriceField label='app.reservation.paid' source='bankPrice' />
        <RemainingPayment label='app.reservation.remainingPayment' />
        <ObservationField label='' source='comment' />
      </Datagrid>
    </List>
  );
};
