import React from 'react';
import { Grid } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorIcon from '@mui/icons-material/Error';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { ShowButton, EditButton, useNotify, useRefresh, Button, useRecordContext, useUpdate } from 'react-admin';

import { WAITING_FOR_ACCEPTANCE, WAITING_FOR_DEPOSIT, RECEIVED, RETURNED } from '../status';
import { SendConfirmationButton } from './SendConfirmationButton';
import { SendInvoiceButton } from './SendInvoiceButton';
import { CancelReservationButton } from './CancelReservationButton';
import { MissingClientReservationButton } from './MissingClientReservationButton';

export const ReservationExpandPanel = (props) => {
  const record = useRecordContext(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const buildRequest = (status) => ({ id: record.id, data: { status }, previousData: record });

  const responses = {
    onSuccess: () => {
      refresh();
      notify('app.reservation.status_updated_success', { type: 'success' });
    },
    onFailure: () => {
      notify('app.reservation.status_updated_failed', { type: 'error' });
    },
  };

  const [set_WAITING_FOR_ACCEPTANCE, { isLoading: loading_WAITING_FOR_ACCEPTANCE }] = useUpdate(
    'reservations',
    buildRequest(WAITING_FOR_ACCEPTANCE),
    responses
  );
  const [set_WAITING_FOR_DEPOSIT, { isLoading: loading_WAITING_FOR_DEPOSIT }] = useUpdate(
    'reservations',
    buildRequest(WAITING_FOR_DEPOSIT),
    responses
  );
  const [set_RECEIVED, { isLoading: loading_RECEIVED }] = useUpdate('reservations', buildRequest(RECEIVED), responses);
  const [set_RETURNED, { isLoading: loading_RETURNED }] = useUpdate('reservations', buildRequest(RETURNED), responses);
  const isLoading =
    loading_WAITING_FOR_ACCEPTANCE || loading_WAITING_FOR_DEPOSIT || loading_RECEIVED || loading_RETURNED;

  return (
    <Grid container rowSpacing={2} columnSpacing={2} sx={{ padding: 2 }}>
      <Grid item xs={3} md={3}>
        <Button
          variant="contained"
          onClick={() => set_WAITING_FOR_ACCEPTANCE()}
          label="Non traité"
          fullWidth
          startIcon={<ErrorIcon />}
          disabled={isLoading || record.status === WAITING_FOR_ACCEPTANCE}
        />
      </Grid>
      <Grid item xs={3} md={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => set_WAITING_FOR_DEPOSIT()}
          label="En attente"
          fullWidth
          startIcon={<HourglassEmptyIcon />}
          disabled={isLoading || record.status === WAITING_FOR_DEPOSIT}
        />
      </Grid>
      <Grid item xs={3} md={3}>
        <ShowButton variant="contained" color="primary" fullWidth resource="reservations" record={record} />
      </Grid>
      <Grid item xs={3} md={3}>
        <EditButton variant="contained" color="primary" fullWidth resource="reservations" record={record} />
      </Grid>
      <Grid item xs={3} md={3}>
        <MissingClientReservationButton fullWidth record={record} />
      </Grid>
      <Grid item xs={3} md={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => set_RECEIVED()}
          label="Réceptionné"
          fullWidth
          startIcon={<FlightTakeoffIcon />}
          disabled={isLoading || record.status === RECEIVED}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <SendConfirmationButton fullWidth record={record} />
      </Grid>
      <Grid item xs={3} md={3}>
        <CancelReservationButton fullWidth {...props} />
      </Grid>
      <Grid item xs={3} md={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => set_RETURNED()}
          label="Rendu"
          fullWidth
          startIcon={<FlightLandIcon />}
          disabled={isLoading || record.status === RETURNED}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <SendInvoiceButton variant="contained" color="primary" fullWidth record={record} />
      </Grid>
    </Grid>
  );
};
