import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  ReferenceField,
  ShowButton,
  DateField,
  BulkUpdateButton,
  TextField,
  TopToolbar,
  ExportButton,
  NumberInput,
  DateInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  FilterButton,
} from 'react-admin';

import { FullNameField } from '../reservations/FullNameField';
import { exporter } from '../components/Exporter';
import { WAITING_FOR_DEPOSIT, WAITING_FOR_ACCEPTANCE } from '../reservations/status';
import { ReservationProviderAvatarField } from '../reservations/ReservationProviderAvatarField';
import { OptionsField } from '../daily/OptionsField';

const filters = [
  <NumberInput label="app.reservation.id" source="id" />,
  <DateInput label='app.reservation.createAt_date-gte' source='createAt_date-gte' />,
  <DateInput label='app.reservation.createAt_date-lte' source='createAt_date-lte' />,
  <DateInput label="app.reservation.deposit" source="deposit" />,
  <DateInput label="app.reservation.pickup" source="pickup" />,
  <TextInput label="app.reservation.carBrand" source="carBrand" />,
  <TextInput label="app.reservation.registration" source="registration" />,
  <TextInput label="app.reservation.stock" source="stock" />,
  <ReferenceInput label="app.user.user" source="userId" reference="users">
    <SelectInput optionText={<FullNameField source="Name" />} />
  </ReferenceInput>,
  <SelectInput
    label="app.reservation.status.title"
    source="status"
    choices={[
      { id: '0', name: 'app.reservation.status.waitingForAcceptance' },
      { id: '1', name: 'app.reservation.status.cancel' },
      { id: '2', name: 'app.reservation.status.waitingForDeposit' },
      { id: '3', name: 'app.reservation.status.missingClient' },
      { id: '4', name: 'app.reservation.status.receive' },
      { id: '5', name: 'app.reservation.status.returned' },
    ]}
  />,
  <TextInput label="app.reservation.transactionComment" source="transactionComment" />,
];

const PostBulkActionButtons = () => (
  <Fragment>
    <BulkUpdateButton
      label="app.waitings.setWaintingButton"
      data={{ status: WAITING_FOR_DEPOSIT }}
      mutationMode="pessimistic"
    />
  </Fragment>
);

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

export const ListWaitingReservations = () => (
  <List
    title="app.waitings.title"
    filters={filters}
    filter={{ status: WAITING_FOR_ACCEPTANCE }}
    exporter={exporter}
    resource="reservations"
    actions={<ListActions />}
  >
    <Datagrid bulkActionButtons={<PostBulkActionButtons />}>
      <ReservationProviderAvatarField label="app.reservation.providerId" />
      <TextField label="app.reservation.id" source="id" />
      <DateField label="app.reservation.createAt" source="createAt" showTime />
      <ReferenceField label="app.user.user" source="userId" reference="users" link="show">
        <FullNameField label="app.user.name" source="Name" />
      </ReferenceField>
      <DateField label="app.reservation.deposit" source="deposit" showTime />
      <DateField label="app.reservation.pickup" source="pickup" showTime />
      <OptionsField label="app.reservation.options" />
      <ShowButton />
    </Datagrid>
  </List>
);
