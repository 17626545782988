import React from 'react';
import { TextField, useRecordContext } from 'react-admin';

/**
 * Format a percentage value.
 */
export const PercentageField = ({ label, source, ...props }) => {
  const record = useRecordContext(props);

  return <TextField {...props} label={label} source="value" record={{ value: `${record[source]} %` }} />;
};
