import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';

import frenchAppMessages from './strings-fr';

const french = {
    ...frenchMessages,
    ...frenchAppMessages
};

const i18nProvider = polyglotI18nProvider(locale => 
    locale === 'fr' ? french : englishMessages,
    'fr' // Default locale
);

export default i18nProvider;