import React from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { OptionIconButtonRoot } from './OptionIconButtonRoot';

export const UnknownOption = (props) => {
  return (
    <OptionIconButtonRoot {...props} backgroundColor='#bdbdbd'>
      <QuestionMarkIcon />
    </OptionIconButtonRoot>
  );
};