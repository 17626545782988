import { isNullOrEmpty } from '../helpers/StringUtils';

export function validatePromoCodeForm(values) {
  const errors = {};

  if (!values.code) {
    errors.code = 'Le code est obligatoire';
  }

  if (values.code && values.code.includes(' ')) {
    errors.code = 'Le code ne doit pas contenir d\'espace';
  }

  if (!values.label) {
    errors.label = 'Ce champ est obligatoire';
  }

  if (!values.start) {
    errors.start = 'Ce champ est obligatoire';
  }

  if (!values.end) {
    errors.end = 'La date de fin est obligatoire';
  }

  if (values.end && new Date(values.end) < new Date()) {
    errors.end = 'La date de fin ne peut pas être avant la date actuelle';
  }

  if (values.end && values.start && new Date(values.end) <= new Date(values.start)) {
    errors.end = 'Date de fin ne pas être avant la date de début';
  }

  if (values.amount < 0) {
    errors.amount = 'Valeur invalide';
  }

  if (values.percentage < 0 || values.percentage > 100) {
    errors.percentage = 'Valeur invalide';
  }

  if (isNullOrEmpty(values.amount) && isNullOrEmpty(values.percentage)) {
    errors.amount = 'Entrer un montant ou un pourcentage';
    errors.percentage = 'Entrer un montant ou un pourcentage';
  }

  if (!isNullOrEmpty(values.amount) && !isNullOrEmpty(values.percentage)) {
    errors.amount = 'Entrer un montant ou un pourcentage';
    errors.percentage = 'Entrer un montant ou un pourcentage';
  }

  if (values.maxUse < 0) {
    errors.maxUse = 'Valeur invalide';
  }

  return errors;
}