import React from 'react';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { OptionIconButtonRoot } from './OptionIconButtonRoot';

export function isElectricCharging(option) {
  const label = option.label.toLowerCase();

  return label.includes('électrique') || label.includes('elec') || label.includes('recharge');
}

export const ElectricCharging = (props) => {
  return (
    <OptionIconButtonRoot {...props} backgroundColor='#F1C40F'>
      <ElectricBoltIcon />
    </OptionIconButtonRoot>
  );
};
